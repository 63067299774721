import { configureStore } from "@reduxjs/toolkit";

import appDataConfigSlice from "./appDataConfigSlice";
import configurationSlice from "./configurationSlice";
import userSlice from "../../components/Login/redux/userSlice";

const store = configureStore({
  reducer: {
    appDataConfig: appDataConfigSlice,
    configurations: configurationSlice,
    user: userSlice,
  },
});

export default store;
