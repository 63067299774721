import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  paper: {
    width: "328px",
    maxWidth: "328px",
    background: "#F5F5F5",
    border: "none",
    borderRadius: "10px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    padding: "16px",
  },
  scrollPaper: {
    alignItems: "flex-end",
    marginLeft: "50%",
  },
  dialogContentStyle: {
    padding: "0px",
    paddingTop: "0px !important",
    border: "none",
  },
  dialogContentDiv: {
    width: "100%",
    height: "32px",
    display: "flex",
    justifyContent: "space-between !important",
  },
  dialogContentFlexStart: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start !important",
  },
  dialogExternalTextDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start !important",
    marginBottom: "24px",
  },
  dialogDivTitleNormal: {
    marginTop: "auto",
    marginBottom: "auto",
    fontFamily: "Montserrat",
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: "bold",
    color: "#252525",
  },
  DialogActionsStyle: {
    margin: 0,
    padding: 0,
  },
  resetButton: {
    height: "48px",
    width: "142px",
    marginRight: "10px",
    background: "#FFFFFF",
    border: "1px solid #0E5AA7",
    borderRadius: "8px",
    outline: "0 !important",
  },
  resetButtonText: {
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: "center",
    color: "#0E5AA7",
  },
  successButton: {
    height: "48px",
    width: "142px",
    marginLeft: "10px",
    background: "#0E5AA7",
    border: "1px solid #0E5AA7",
    borderRadius: "8px",
    outline: "0 !important",
  },
  successButtonText: {
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: "center",
    color: "#FFFFFF",
  },
  mainDivColumn: {
    display: "flex",
    width: "100%",
    marginTop: "15px",
    marginBottom: "15px",
    paddingRight: "0px",
    paddingTop: "0px",
  },
  NewUserInputBox: {
    display: "block",
    width: "100%",
    height: "56px",
    padding: "12px",
    background: "#FFFFFF",
    border: "1px solid #B2B2B2",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#252525",
    outline: "0 !important",
    backgroundColor: "#FFFFFF !important",
    textAlign: "center",
    "&::placeholder": {
      padding: "0",
      color: "#B2B2B2",
      textAlign: "center",
      width: "100%",
    },
  },
  externalImgStyle: {
    margin: 0,
    padding: 0,
    marginRight: "8px",
  },
  externalTextStyle: {
    marginTop: "auto",
    marginBottom: "auto",
    fontFamily: "Montserrat",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#252525",
  },
}));
