import { Redirect, Route } from "react-router-dom";

import React from "react";
import PropTypes from "prop-types";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const currentUser = localStorage.getItem("currentUser");
      if (!currentUser) {
        return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
      }
      return <Component {...props} />;
    }}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.any,
};

export default PrivateRoute;
