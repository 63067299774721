import { makeStyles } from "@material-ui/core";

export const dashboardStyles = makeStyles(() => ({
  dashboardPageStyle: {
    margin: "80px 20px 20px 30px",
    height: "calc(100vh - 100px)",
  },
  dashboardMainContentStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  mainContentLeft: {
    width: "65%",
    padding: 0,
    margin: 0,
    paddingRight: "24px",
  },
  mainContentRight: {
    width: "35%",
    marginRight: 0,
    padding: 0,
  },
  loadingMainDiv: {
    height: "100%",
    width: "100%",
    display: "table",
  },
  loadingDiv: {
    height: "calc(100vh - 189px)",
    width: "100%",
    display: "table",
  },
  loadingMainDivInner: {
    height: "100%",
    width: "100%",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
  },
  toastStyle: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    lineHeight: "17px",
    borderRadius: "8px",
    hideProgressBar: false,
  },
}));
