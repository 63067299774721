export async function handleResponse(response) {
  if (response.ok) {
    let result = await response.json();
    return result;
  } else {
    if (response.status == "401") {
      localStorage.clear();
      location.reload();
    }
    const errorBody = await response.json();
    console.error(errorBody);
    if (!errorBody.success) {
      if (errorBody.errors && errorBody.errors.length > 0) {
        throw new Error(errorBody.errors[0].errorMessage);
      }
    } else {
      throw new Error("Error occured while processing your request.");
    }
  }
}

export async function handleResponseLogin(response) {
  if (response.ok) {
    let result = await response.json();
    return result;
  } else {
    const errorBody = await response.json();
    console.error(errorBody);
    if (!errorBody.success) {
      if (errorBody.errors && errorBody.errors.length > 0) {
        throw new Error(errorBody.errors[0].errorMessage);
      }
    } else {
      throw new Error("Error occured while processing your request.");
    }
  }
}

// TODO : call an error logging service.
export function handleError(error) {
  console.error("API call failed. " + error);
  throw error;
}
