// Fetch.js
async function request(url, params, method = "GET") {
  let token = "";
  let currentUser = localStorage.getItem("currentUser");
  if (currentUser != null) {
    token = JSON.parse(currentUser).token;
  }
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      zoneid: "UTC",
      Authorization: "Bearer " + token,
    },
  };

  if (params) {
    if (method === "GET") {
      url += "?" + objectToQueryString(params);
    } else {
      options.body = JSON.stringify(params);
    }
  }
  const response = await fetch(url, options);
  return response;
}

function objectToQueryString(obj) {
  let queryString = "";
  for (let [key, value] of Object.entries(obj)) {
    if (value != "" && value != {}) {
      if ((typeof value === "string" || value instanceof String) && value.indexOf("&") !== -1) {
        value = value.replace("&", "%26"); // Replace '&' with '%26'
      }
      if (queryString === "") {
        queryString = key + "=" + value;
      } else {
        queryString = queryString + "&" + key + "=" + value;
      }
    }
  }
  return queryString;
}

function get(url, params) {
  return request(url, params);
}

function create(url, params) {
  return request(url, params, "POST");
}

function update(url, params) {
  return request(url, params, "PUT");
}

function remove(url, params) {
  return request(url, params, "DELETE");
}

async function openRequest(url) {
  const options = {
    method: "GET",
  };
  const response = await fetch(url, options);
  return response;
}

function openGet(url) {
  return openRequest(url);
}

export default {
  get,
  create,
  update,
  remove,
  openGet,
};
