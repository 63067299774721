import "bootstrap-daterangepicker/daterangepicker.css";

import React, { useState } from "react";
import PropTypes from "prop-types";

import { DateRangePicker } from "react-bootstrap-daterangepicker";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { searchBarStyle } from "./SearchBarStyle";
import DropDownIcon from "../../assets/svg/DropDownIcon.svg";

const DashboardTopSearchBarPage = ({ topMenuSearchObj, delLabel, searchOnChange, handleDateChangeEvent, handleCancel, slotList }) => {
  const searchBarClasses = searchBarStyle();
  const dateRangeCriteriaValue = "EQUAL_TO";

  const [isOpen, setIsOpen] = useState(false);

  const newIcon = () => {
    return <img src={DropDownIcon} className={searchBarClasses.dropDownIconStyle} />;
  };

  return (
    <div className={searchBarClasses.topSearchBarStyle}>
      <div className={searchBarClasses.topSearchBarLeft}>
        <div className={searchBarClasses.topSearchBarLeftLeft}>
          <span className={searchBarClasses.dashboardTextstyle}>Dashboard</span>
        </div>
        <div className={searchBarClasses.topSearchBarLeftRight}>
          <div className={searchBarClasses.searchInputDiv}>
            <FormControl variant="filled">
              <InputLabel
                classes={{
                  root: searchBarClasses.labelOnInputBoxFontStyle,
                  focused: searchBarClasses.labelOnInputBoxFontStyle,
                }}
              >
                Slot
              </InputLabel>
              <Select
                id="slotId"
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                value={topMenuSearchObj.slotId}
                onChange={searchOnChange}
                label="Slot"
                name="slotId"
                className={isOpen ? searchBarClasses.selectBoxOpenInputDivStyle : searchBarClasses.selectBoxInputDivStyle}
                disableUnderline={true}
                IconComponent={newIcon}
                MenuProps={{
                  anchorOrigin: { vertical: "bottom", horizontal: "left" },
                  getContentAnchorEl: null,
                  classes: { paper: searchBarClasses.selectBoxDropdownPaper },
                }}
              >
                {slotList != null && Array.isArray(slotList) && slotList.length != null && slotList.length > 0
                  ? slotList.map((slot, slotIndex) => (
                      <MenuItem className={searchBarClasses.selectBoxMenuItemTextStyle} value={slot.slotName} key={"slotIndex-" + slotIndex}>
                        {slot.slotName || "-"}
                      </MenuItem>
                    ))
                  : []}
              </Select>
            </FormControl>
          </div>
          <div className={searchBarClasses.searchInputDiv}>
            <div className={searchBarClasses.searchInputDateDiv}>
              <FormControl variant="filled">
                <Select
                  id="dateRangeCriteria"
                  name="dateRangeCriteria"
                  value={dateRangeCriteriaValue}
                  className={searchBarClasses.dateSearchBoxSelect}
                  disableUnderline={true}
                  classes={{ icon: searchBarClasses.dateSearchBoxSelectIcon }}
                  disabled
                >
                  <MenuItem className={searchBarClasses.fontStyle} value="EQUAL_TO">
                    =
                  </MenuItem>
                </Select>
              </FormControl>
              <DateRangePicker
                onEvent={handleDateChangeEvent}
                onCancel={handleCancel}
                initialSettings={{
                  singleDatePicker: true,
                  autoUpdateInput: false,
                  cancelButtonClasses: "btn-primary",
                  locale: { format: "YYYY-MM-DD", cancelLabel: "Clear" },
                }}
              >
                <input type="text" name="delDate" placeholder="Date" onChange={handleDateChangeEvent} className={searchBarClasses.dateSearchBoxOneDate} value={delLabel} />
              </DateRangePicker>
            </div>
          </div>
        </div>
      </div>

      <div className={searchBarClasses.topSearchBarRight}>
        <button className={searchBarClasses.startPreparationButton} disabled={true}>
          <span className={searchBarClasses.startPreparationButtonText}>START PREPERATION</span>
        </button>
      </div>
    </div>
  );
};

DashboardTopSearchBarPage.propTypes = {
  topMenuSearchObj: PropTypes.object.isRequired,
  delLabel: PropTypes.any.isRequired,
  searchOnChange: PropTypes.func.isRequired,
  handleDateChangeEvent: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  slotList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default React.memo(DashboardTopSearchBarPage);
