import * as courseApi from "../../../api/DashboardAPI";

export const getCounterItemsReport = (data, handleRetrievedData, handleError) => {
  courseApi
    .fetchCounterItemsReport(data)
    .then((response) => {
      handleRetrievedData(response);
    })
    .catch(handleError);
};

export const getSlotList = (data, handleRetrievedData, handleError) => {
  courseApi
    .fetchSlotList(data)
    .then((response) => {
      handleRetrievedData(response);
    })
    .catch(handleError);
};

export const getCounterItemsCount = (data, handleRetrievedData, handleError) => {
  courseApi
    .fetchCounterItemsCount(data)
    .then((response) => {
      handleRetrievedData(response);
    })
    .catch(handleError);
};

export const getCounterItemsPrepareApiCall = (data, handleRetrievedData, handleError) => {
  courseApi
    .fetchCounterItemsPrepareApiCall(data)
    .then((response) => {
      handleRetrievedData(response);
    })
    .catch(handleError);
};
