import React from "react";
import PropTypes from "prop-types";

import { itemCountStyle } from "./ItemCountStyle";

const DashboardItemsCountPage = ({ itemListData, selectedCountItem, handleCountItemChange }) => {
  const itemContClasses = itemCountStyle();

  return (
    <div className={itemContClasses.countMainDiv}>
      {itemListData != null && Array.isArray(itemListData) && itemListData.length != null && itemListData.length > 0 ? (
        itemListData.map((item, itemIndex) => (
          <div className={itemContClasses.countDiv} key={"CounterItemCount-" + itemIndex}>
            <div className={selectedCountItem === itemIndex ? itemContClasses.countBoxActive : itemContClasses.countBox} onClick={(event) => handleCountItemChange(event, itemIndex, item)}>
              <span className={selectedCountItem === itemIndex ? itemContClasses.countBoxActiveText : itemContClasses.countBoxText}>{item.name}</span>
              <span className={selectedCountItem === itemIndex ? itemContClasses.countBoxActiveTextBold : itemContClasses.countBoxTextBold}>{item.count}</span>
            </div>
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
};

DashboardItemsCountPage.propTypes = {
  itemListData: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCountItem: PropTypes.number.isRequired,
  handleCountItemChange: PropTypes.func.isRequired,
};

export default React.memo(DashboardItemsCountPage);
