import { handleError, handleResponse } from "./apiUtils";
import Fetch from "./Fetch";
import { _apiItemCategoryListURL } from "./FetchUtils";

export function fetchCounterItemsReport(objData) {
  const url = `${_apiItemCategoryListURL}/fetchCounterItems`;
  return Fetch.get(url, objData).then(handleResponse).catch(handleError);
}

export function fetchCounterItemsCount(objData) {
  const url = `${_apiItemCategoryListURL}/express_hypermarket`;
  return Fetch.get(url, objData).then(handleResponse).catch(handleError);
}

export function fetchCounterItemsPrepareApiCall(objData) {
  const url = `${_apiItemCategoryListURL}/counteritemsprepartionmarking?store=${objData.storeCode}&source=${objData.source}`;
  return Fetch.update(url, objData.prepareData).then(handleResponse).catch(handleError);
}

export function fetchSlotList(objData) {
  // const url = `${_apiReportingServiceBaseURL}/api/hypermarket/v1/preparation/standard-orders/slots`;
  const url = `${_apiItemCategoryListURL}/getslotsinfoforalectoandmfc`;
  return Fetch.get(url, objData).then(handleResponse).catch(handleError);
}
