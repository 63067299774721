import { Link } from "react-router-dom";
import React from "react";

import { commonErrorStyles } from "./Styles/commonErrorStyles";
import { APP_ROUTES } from "../../config/AppConstant";

const PageNotFound = () => {
  const classes = commonErrorStyles();

  return (
    <div className={classes.loadingMainDiv}>
      <div className={classes.loadingMainDivInner}>
        <span className={classes.errorNameStyle}>Oops!!!</span>
        <span className={classes.errorNameStyle}>404 Not Found</span>
        <span className={classes.errorNameStyle}>Sorry, an error has occured, Requested page not found!</span>

        <Link to={APP_ROUTES.HOME}>Take Me Home</Link>
      </div>
    </div>
  );
};

export default PageNotFound;
