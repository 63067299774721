export const TopMenuSearchData = {
  slotId: "",
  slotStartTime: "",
  slotEndTime: "",
  date: "",
  category: "",
  store: "",
  orderType: "ALL", // CARREFOUR_NOW, STANDARD, ALL
  prepared: false,
  pageNumber: 0,
  recordsPerPage: 50,
};

export const ItemCountListData = [
  {
    id: "totalCount",
    name: "Total Items",
    count: 0,
    orderType: "ALL",
    prepared: false,
  },
  {
    id: "pendingCarrefourNowItemsCount",
    name: "Carrefour NOW",
    count: 0,
    orderType: "CARREFOUR_NOW",
    prepared: false,
  },
  {
    id: "pendingStandardItemsCount",
    name: "Standard Items",
    count: 0,
    orderType: "STANDARD",
    prepared: false,
  },
  {
    id: "completedItemsCount",
    name: "Completed Items",
    count: 0,
    orderType: "ALL",
    prepared: true,
  },
];
