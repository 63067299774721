import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

const DividerTextStyles = makeStyles(() => ({
  container: {
    margin: "18px 0",
    display: "flex",
    alignItems: "center",
  },
  borderStyle: {
    border: "1px solid lightgrey",
    width: "100%",
  },
  content: {
    width: "100%",
    padding: "0 10px 0 10px",
    fontFamily: "Montserrat",
    fontSize: "15px",
    fontWeight: "500",
    lineHeight: "8px",
    color: "#252525",
  },
}));

const DividerText = ({ children = "No Data Found" }) => {
  const classes = DividerTextStyles();

  return (
    <div className={classes.container}>
      <div className={classes.borderStyle} />
      <span className={classes.content}>{children}</span>
      <div className={classes.borderStyle} />
    </div>
  );
};

DividerText.propTypes = {
  children: PropTypes.string.isRequired,
};
export default DividerText;
