import React from "react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  outOfStockTextStyle: {
    marginTop: "auto",
    marginBottom: "auto",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "10px",
    lineHeight: "12px",
    color: "#DD2326",
  },
});

const OutOfStockText = () => {
  const classes = useStyles();

  return <span className={classes.outOfStockTextStyle}>Out of stock</span>;
};

export default OutOfStockText;
