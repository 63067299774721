import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { login, loginByMicrosoftAPI } from "../../../api/loginAPI";
import { CURRENT_COUNTRY_CODE, POS_CODE, CATEGORY_CODE } from "../../../config/AppConstant";

export const loginUser = createAsyncThunk("user/login", async (userData, { rejectWithValues }) => {
  try {
    localStorage.removeItem("currentUser");
    localStorage.removeItem(CURRENT_COUNTRY_CODE);
    localStorage.removeItem(POS_CODE);
    localStorage.removeItem(CATEGORY_CODE);
    const responseData = await login(userData);
    if (responseData != null && responseData.success && responseData?.data && responseData.data?.name && responseData.data?.token) {
      localStorage.setItem(
        "currentUser",
        JSON.stringify({
          token: responseData.data.token,
          name: responseData.data.name,
        })
      );
      return responseData.data;
    }
    return responseData;
  } catch (err) {
    return rejectWithValues(err);
  }
});

export const loginByMicrosoft = createAsyncThunk("user/loginByMicrosoft", async (userData, { rejectWithValues }) => {
  try {
    localStorage.removeItem("currentUser");
    localStorage.removeItem(CURRENT_COUNTRY_CODE);
    localStorage.removeItem(POS_CODE);
    localStorage.removeItem(CATEGORY_CODE);
    const responseData = await loginByMicrosoftAPI(userData);
    if (responseData != null && responseData.success && responseData?.data && responseData.data?.token) {
      localStorage.setItem(
        "currentUser",
        JSON.stringify({
          token: responseData.data.token,
          name: responseData.data.name,
        })
      );
      return responseData.data;
    }
    return responseData;
  } catch (err) {
    return rejectWithValues(err);
  }
});

export const logOutUser = createAsyncThunk("user/logout", () => {
  localStorage.removeItem("currentUser");
  localStorage.removeItem(CURRENT_COUNTRY_CODE);
  localStorage.removeItem(POS_CODE);
  localStorage.removeItem(CATEGORY_CODE);
  // console.clear();
  return false;
});

const initialState = {
  isLoggedIn: localStorage.getItem("currentUser") ? true : false,
  loading: false,
  userDetails: localStorage.getItem("currentUser") ? localStorage.getItem("currentUser") : {},
  loginError: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateLoadingState: (state, action) => {
      state.loading = action.payload;
    },
    updateLoginErrorState: (state, action) => {
      state.loginError = action.payload;
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.isLoggedIn = false;
      state.loading = true;
      state.userDetails = {};
      state.loginError = {};
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      if (payload && payload.name && payload.name != "" && payload.token && payload.token != "") {
        state.isLoggedIn = true;
        state.userDetails = { ...payload };
        state.loginError = {};
      } else {
        state.isLoggedIn = false;
        state.userDetails = {};
        state.loginError = payload;
      }
      state.loading = false;
    },
    [loginUser.rejected]: (state) => {
      state.loading = false;
      state.isLoggedIn = false;
      state.loginError = { errors: "Error Occured while Login" };
    },
    [logOutUser.pending]: (state) => {
      state.loading = false;
      state.isLoggedIn = false;
      state.userDetails = {};
      state.loginError = {};
    },
    [logOutUser.fulfilled]: (state) => {
      state.loading = false;
      state.isLoggedIn = false;
      state.userDetails = {};
      state.loginError = {};
    },
    [logOutUser.rejected]: (state) => {
      state.loading = false;
      state.isLoggedIn = false;
      state.userDetails = {};
      state.loginError = {};
    },
    [loginByMicrosoft.pending]: (state) => {
      state.isLoggedIn = false;
      state.loading = true;
      state.userDetails = {};
      state.loginError = {};
    },
    [loginByMicrosoft.fulfilled]: (state, { payload }) => {
      if (payload && payload.name && payload.name != "" && payload.token && payload.token != "") {
        state.isLoggedIn = true;
        state.userDetails = { ...payload };
        state.loginError = {};
      } else {
        state.isLoggedIn = false;
        state.userDetails = {};
        state.loginError = payload;
      }
      state.loading = false;
    },
    [loginByMicrosoft.rejected]: (state) => {
      state.loading = false;
      state.isLoggedIn = false;
      state.loginError = { errors: "Error Occured while Login" };
    },
  },
});

export default userSlice.reducer;

export const { updateLoadingState, updateLoginErrorState } = userSlice.actions;
export const selectedUserDetails = (state) => state.user.userDetails;
