import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router";
import { Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_RESPONSE_STATUS, APP_ROUTES, CATEGORY_CODE, CURRENT_COUNTRY_CODE, POS_CODE } from "../config/AppConstant";
import { changeCategoryCode, changeCountryCode, changePosCode } from "../config/redux/appDataConfigSlice";
import { fetchListOfCategory, fetchListOfCountries, fetchListOfPosByCountry, resetAppConfigData } from "../config/redux/configurationSlice";
import InvalidUserPage from "./common/InvalidUserPage";
import PageNotFound from "./common/PageNotFound";
import PrivateRoute from "./common/Routes/PrivateRoute";
import DashboardPage from "./Dashboard/DashboardPage";
import { logOutUser } from "./Login/redux/userSlice";
import UserLoginPage from "./Login/UserLoginPage";


function App() {
  const { isLoggedIn } = useSelector((state) => state.user);
  const { countries, posList, categoriesList } = useSelector((state) => state.configurations);

  const dispatch = useDispatch();

  const fetchConfigurations = useCallback(() => {
    if (countries.fetchStatus === API_RESPONSE_STATUS.IDLE) {
      dispatch(fetchListOfCountries());
    }
    if (countries.fetchStatus === API_RESPONSE_STATUS.FAILED) {
      dispatch(logOutUser());
      dispatch(resetAppConfigData());
    }

    if (countries.fetchStatus === API_RESPONSE_STATUS.SUCCEEDED) {
      if (countries.data != null && Array.isArray(countries.data) && countries.data.length != null && countries.data.length > 0) {
        if (posList.fetchStatus === API_RESPONSE_STATUS.IDLE) {
          if (localStorage.getItem(CURRENT_COUNTRY_CODE) != null && localStorage.getItem(CURRENT_COUNTRY_CODE) != "" && countries.data.find((item) => item.id == localStorage.getItem(CURRENT_COUNTRY_CODE)) != null) {
            dispatch(fetchListOfPosByCountry(localStorage.getItem(CURRENT_COUNTRY_CODE)));
            dispatch(changeCountryCode(localStorage.getItem(CURRENT_COUNTRY_CODE)));
          } else {
            localStorage.setItem(CURRENT_COUNTRY_CODE, countries.data[0].id);
            dispatch(fetchListOfPosByCountry(countries.data[0].id));
            dispatch(changeCountryCode(countries.data[0].id));
          }
        }
      }
    }

    if (posList.fetchStatus === API_RESPONSE_STATUS.SUCCEEDED) {
      if (posList && posList.data && Array.isArray(posList.data) && posList.data.length != null && posList.data.length > 0) {
        if (localStorage.getItem(POS_CODE) != null && localStorage.getItem(POS_CODE) != "" && posList.data.find((item) => item.code == localStorage.getItem(POS_CODE)) != null) {
          dispatch(changePosCode(localStorage.getItem(POS_CODE)));
        } else {
          localStorage.setItem(POS_CODE, posList.data[0].code);
          dispatch(changePosCode(posList.data[0].code));
        }
      }
    }

    if (categoriesList.fetchStatus === API_RESPONSE_STATUS.IDLE) {
      dispatch(fetchListOfCategory());
    }

    if (categoriesList.fetchStatus === API_RESPONSE_STATUS.SUCCEEDED) {
      if (categoriesList && categoriesList.data && Array.isArray(categoriesList.data) && categoriesList.data.length != null && categoriesList.data.length > 0) {
        if (localStorage.getItem(CATEGORY_CODE) != null && localStorage.getItem(CATEGORY_CODE) != "" && categoriesList.data.find((item) => item.code == localStorage.getItem(CATEGORY_CODE)) != null) {
          dispatch(changeCategoryCode(localStorage.getItem(CATEGORY_CODE)));
        } else {
          localStorage.setItem(CATEGORY_CODE, categoriesList.data[0].code);
          dispatch(changeCategoryCode(categoriesList.data[0].code));
        }
      }
    }
  }, [countries.fetchStatus, countries.data.length, posList.fetchStatus, posList.data.length, categoriesList.fetchStatus, categoriesList.data.length]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchConfigurations();
    }
  }, [isLoggedIn, fetchConfigurations]);

  return (
    <>
      <Switch>
        <Route exact path={APP_ROUTES.HOME} component={UserLoginPage} />
        <Route exact path={APP_ROUTES.LOGIN} component={UserLoginPage} />
        <PrivateRoute exact path={APP_ROUTES.DASHBOARD} component={DashboardPage} />
        <Route exact path={APP_ROUTES.INVALID_USER_PAGE} component={InvalidUserPage} />
        <Route component={PageNotFound} />
      </Switch>
      <ToastContainer autoClose={3000} hideProgressBar />
    </>
  );
}

export default React.memo(App);
