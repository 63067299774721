import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { AppBar, FormControl, InputLabel, ListItemIcon, MenuItem, Typography, Select } from "@material-ui/core";

import { topMenuStyles } from "./TopMenuStyles";

import DropDownIcon from "../assets/svg/DropDownIcon.svg";
import TopMenuDivider from "../assets/svg/TopMenuDivider.svg";
import UserIcon from "../assets/svg/UserIcon.svg";

import { resetAppConfigData, fetchListOfPosByCountry } from "../../config/redux/configurationSlice";

import { API_RESPONSE_STATUS, CURRENT_COUNTRY_CODE, POS_CODE, CATEGORY_CODE } from "../../config/AppConstant";
import { changeCountryCode, changePosCode, changeCategoryCode, resetAppDataConfig } from "../../config/redux/appDataConfigSlice";
import { logOutUser } from "../Login/redux/userSlice";

const TopMenuPage = () => {
  const topMenuClasses = topMenuStyles();

  const dispatch = useDispatch();
  const currentUserRole = "-";

  const { currentPosCode, currentCategoryCode } = useSelector((state) => state.appDataConfig);
  const { countries, posList, categoriesList } = useSelector((state) => state.configurations);
  const { currentCountryCode } = useSelector((state) => state.appDataConfig);

  const [currentUserDetails, setCurrentUserDetails] = useState({}); // TopMenuPage

  const [selectedCountryCode, setSelectedCountryCode] = useState(""); // TopMenuPage
  const [selectedPosCode, setSelectedPosCode] = useState(""); // TopMenuPage
  const [selectedCategoryCode, setSelectedCategoryCode] = useState(""); // TopMenuPage

  const { userDetails } = useSelector((state) => state.user);

  const newIcon = () => {
    return <img src={DropDownIcon} className={topMenuClasses.DropDownIcon} />;
  };

  useEffect(() => {
    if (
      selectedCountryCode === "" &&
      countries &&
      countries.fetchStatus === API_RESPONSE_STATUS.SUCCEEDED &&
      countries?.data &&
      Array.isArray(countries.data) &&
      countries.data.length != null &&
      countries.data.length > 0
    ) {
      if (currentCountryCode == "") {
        setSelectedCountryCode(countries.data[0].id);
      } else {
        setSelectedCountryCode(currentCountryCode);
      }
    }
  }, [countries, countries.fetchStatus, countries.data.length, currentCountryCode]);

  useEffect(() => {
    if (
      currentPosCode &&
      selectedPosCode != currentPosCode &&
      posList &&
      posList.fetchStatus === API_RESPONSE_STATUS.SUCCEEDED &&
      posList?.data &&
      Array.isArray(posList.data) &&
      posList.data.length != null &&
      posList.data.length > 0
    ) {
      setSelectedPosCode(currentPosCode);
    }
  }, [posList, posList.fetchStatus, posList.data.length, currentPosCode]);

  useEffect(() => {
    if (
      selectedCategoryCode === "" &&
      categoriesList &&
      categoriesList.fetchStatus === API_RESPONSE_STATUS.SUCCEEDED &&
      categoriesList?.data &&
      Array.isArray(categoriesList.data) &&
      categoriesList.data.length != null &&
      categoriesList.data.length > 0
    ) {
      setSelectedCategoryCode(currentCategoryCode);
    }
  }, [categoriesList, categoriesList.fetchStatus, categoriesList.data.length, currentCategoryCode]);

  const handleCodeChange = (event) => {
    event.preventDefault();
    if (event && event?.target) {
      const { name, value } = event.target;
      switch (name) {
        case "selectedCountryCode": {
          setSelectedCountryCode(value);
          localStorage.setItem(CURRENT_COUNTRY_CODE, value);
          dispatch(changeCountryCode(value));
          dispatch(fetchListOfPosByCountry(value));
          break;
        }
        case "selectedPosCode": {
          setSelectedPosCode(value);
          localStorage.setItem(POS_CODE, value);
          dispatch(changePosCode(value));
          break;
        }
        case "selectedCategoryCode": {
          setSelectedCategoryCode(value);
          localStorage.setItem(CATEGORY_CODE, value);
          dispatch(changeCategoryCode(value));
          break;
        }
        default:
          console.error("No input box found");
      }
    }
  };

  useEffect(() => {
    if (userDetails != null && userDetails != undefined) {
      if (userDetails.name != null && userDetails.name != "") {
        setCurrentUserDetails(userDetails);
      } else {
        setCurrentUserDetails(JSON.parse(userDetails));
      }
    }
  }, [userDetails]);

  const logOut = () => {
    dispatch(logOutUser());
    dispatch(resetAppConfigData());
    dispatch(resetAppDataConfig());
  };

  return (
    <AppBar position="fixed" className={topMenuClasses.appBar}>
      <div className={topMenuClasses.topBar}>
        <div className={topMenuClasses.topBarLeft}>
          <span className={topMenuClasses.mainTitle}>ALECTO</span>
        </div>
        <div className={topMenuClasses.topBarRight}>
          <MenuItem className={topMenuClasses.topMenuItem} classes={{ selected: topMenuClasses.topMenuItemFocus }}>
            <div className={topMenuClasses.topMenuFormDivStyle}>
              {posList && posList?.data && Array.isArray(posList.data) && posList.data.length != null && posList.data.length === 1 ? (
                <>
                  <InputLabel className={topMenuClasses.inputLabelStyle}>Store Code: </InputLabel>
                  <span className={topMenuClasses.listFontStyle}>
                    {posList.data[0].code}
                    <span className={topMenuClasses.listFontStyleNoBold}>({posList.data[0].description})</span>
                  </span>
                </>
              ) : (
                <>
                  <InputLabel className={topMenuClasses.inputLabelStyle}>Store Code: </InputLabel>
                  <FormControl>
                    <Select
                      disableUnderline={true}
                      id="selectedPosCode"
                      value={selectedPosCode}
                      onChange={handleCodeChange}
                      label="selectedPosCode"
                      name="selectedPosCode"
                      className={topMenuClasses.selectMenuInputStyle}
                      IconComponent={newIcon}
                      classes={{ select: topMenuClasses.topMenuItemFocus }}
                      MenuProps={{ anchorOrigin: { vertical: "bottom", horizontal: "left" }, getContentAnchorEl: null, classes: { paper: topMenuClasses.selectDropdown } }}
                    >
                      {posList && posList.data != null && Array.isArray(posList.data)
                        ? posList.data.map((item, index) => (
                            <MenuItem className={topMenuClasses.listFontStyle} key={"posList-" + index} value={item.code}>
                              <span>
                                {item.code}
                                <span className={topMenuClasses.listFontStyleNoBold}>({item.description})</span>
                              </span>
                            </MenuItem>
                          ))
                        : []}
                    </Select>
                  </FormControl>
                </>
              )}

              <img src={TopMenuDivider} className={topMenuClasses.topMenuDividerStyle} />

              <InputLabel className={topMenuClasses.inputLabelStyle}>Category: </InputLabel>
              <FormControl>
                <Select
                  disableUnderline={true}
                  id="selectedCategoryCode"
                  value={selectedCategoryCode}
                  onChange={handleCodeChange}
                  label="selectedCategoryCode"
                  name="selectedCategoryCode"
                  className={topMenuClasses.selectMenuInputStyle}
                  IconComponent={newIcon}
                  classes={{ select: topMenuClasses.topMenuItemFocus }}
                  MenuProps={{ anchorOrigin: { vertical: "bottom", horizontal: "left" }, getContentAnchorEl: null, classes: { paper: topMenuClasses.selectDropdown } }}
                >
                  {categoriesList && categoriesList.data != null && Array.isArray(categoriesList.data)
                    ? categoriesList.data.map((item, index) => (
                        <MenuItem className={topMenuClasses.listFontStyle} key={"countryList-" + index} value={item.code}>
                          {item.name}
                        </MenuItem>
                      ))
                    : []}
                </Select>
              </FormControl>

              <img src={TopMenuDivider} className={topMenuClasses.topMenuDividerStyle} />

              <InputLabel className={topMenuClasses.inputLabelStyle}>Country: </InputLabel>
              <FormControl>
                <Select
                  disableUnderline={true}
                  id="selectedCountryCode"
                  value={selectedCountryCode}
                  onChange={handleCodeChange}
                  label="selectedCountryCode"
                  name="selectedCountryCode"
                  className={topMenuClasses.selectMenuInputStyle}
                  IconComponent={newIcon}
                  classes={{ select: topMenuClasses.topMenuItemFocus }}
                  MenuProps={{ anchorOrigin: { vertical: "bottom", horizontal: "left" }, getContentAnchorEl: null, classes: { paper: topMenuClasses.selectDropdown } }}
                >
                  {countries && countries.data != null && Array.isArray(countries.data)
                    ? countries.data.map((item, index) => (
                        <MenuItem className={topMenuClasses.listFontStyle} key={"countryList-" + index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    : []}
                </Select>
              </FormControl>

              <img src={TopMenuDivider} className={topMenuClasses.topMenuDividerStyle} />

              <Typography className={topMenuClasses.userDetail} onClick={logOut}>
                {currentUserDetails?.name || "N/A"}
                <br />
                <span className={topMenuClasses.userRole}>{currentUserRole}</span>
              </Typography>
              <ListItemIcon className={topMenuClasses.accountCircleDiv} onClick={logOut}>
                <img src={UserIcon} className={topMenuClasses.userIconStyle} />
              </ListItemIcon>
            </div>
          </MenuItem>
        </div>
      </div>
    </AppBar>
  );
};

export default React.memo(TopMenuPage);
