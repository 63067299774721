import React from "react";

import { useDispatch } from "react-redux";

import { logOutUser } from "../Login/redux/userSlice";
import { resetAppConfigData } from "../../config/redux/configurationSlice";
import { commonErrorStyles } from "./Styles/commonErrorStyles";

const InvalidUserPage = () => {
  const classes = commonErrorStyles();
  const dispatch = useDispatch();

  const logOutUserClick = () => {
    dispatch(logOutUser());
    dispatch(resetAppConfigData());
  };

  return (
    <div className={classes.loadingMainDiv}>
      <div className={classes.loadingMainDivInner}>
        <span className={classes.errorNameStyle}>Invalid User.</span>
        <br />
        <span className={classes.errorNameStyle}>Cannot Access Page.</span>
        <br />
        <button onClick={logOutUserClick} className={classes.logoutButtonStyle}>
          <span className={classes.logoutButtonTextStyle}>Logout User</span>
        </button>
      </div>
    </div>
  );
};

export default InvalidUserPage;
