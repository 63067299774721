import React from "react";

import CarrefourNowBikeIcon from "../assets/svg/CarrefourNowBikeIcon.svg";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  carrefourNowDiv: {
    display: "flex",
    justifyContent: "space-between",
    pointerEvents: "none",
    width: "70px",
    height: "24px",
    margin: "0px",
    padding: "0px",
    paddingLeft: "8px",
    paddingRight: "8px",
    background: "#FFFFFF",
    border: "1px solid #0E5AA7",
    borderRadius: "100px",
    outline: "0 !important",
    marginTop: "auto",
    marginBottom: "auto",
  },
  itemDetailsBikeImageStyle: {
    width: "14px",
    height: "14px",
    marginTop: "auto",
    marginBottom: "auto",
    pointerEvents: "none",
  },
  itemCarrefourTextStyle: {
    marginTop: "auto",
    marginBottom: "auto",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#0E5AA7",
  },
});

const CarrefourButton = () => {
  const classes = useStyles();

  return (
    <div className={classes.carrefourNowDiv}>
      <img src={CarrefourNowBikeIcon} className={classes.itemDetailsBikeImageStyle} />
      <span className={classes.itemCarrefourTextStyle}>NOW</span>
    </div>
  );
};

export default CarrefourButton;
