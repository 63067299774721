import { makeStyles } from "@material-ui/core";

export const itemDetailsStyle = makeStyles(() => ({
  mainDiv: {
    margin: 0,
    padding: "24px",
    width: "100%",
    height: "calc(100vh - 186px)",
    background: "#FFFFFF",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    overflow: "auto",
  },
  itemDetailMainDiv: {
    width: "100%",
    height: "100%",
  },
  scanProductDivStyle: {
    width: "100%",
    height: "104px",
    marginTop: "20px",
    cursor: "pointer",
  },
  scanBarCodeTextDiv: {
    width: "100%",
    height: "32px",
    cursor: "pointer",
    textAlign: "center",
  },
  loadingMainDiv: {
    height: "100%",
    width: "100%",
    display: "table",
  },
  loadingMainDivInner: {
    height: "100%",
    width: "100%",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
  },
  NoDataAvailableStyle: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#252525",
  },
  itemCountStyle: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#0A437C",
  },
  itemDetailsDiv: {
    display: "grid",
    marginTop: "20px",
  },
  itemNameStyle: {
    margin: 0,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#031629",
  },
  itemDescriptionStyle: {
    margin: 0,
    marginTop: "8px",
    marginBottom: "12px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#666666",
  },
  carrefournNowButton: {
    margin: 0,
    marginBottom: "12px",
  },
  itemDetailsImageDiv: {
    width: "100%",
    height: "200px",
    textAlign: "center",
  },
  itemDetailsImageStyle: {
    width: "200px",
    height: "200px",
    margin: "0px",
    padding: "0px",
  },
  notAvailableButtonStyle: {
    margin: 0,
    padding: 0,
    marginTop: "10px",
    marginBottom: "20px",
    height: "36px",
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid #0E5AA7",
    borderRadius: "4px",
    outline: "0 !important",
  },
  notAvailableButtonTextStyle: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#0E5AA7",
  },
  emptyNotAvailableDiv: {
    width: "100%",
    height: "24px",
  },
  imageLeftRightDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  ScanBarCodeInputBox: {
    display: "block",
    width: "100%",
    height: "32px",
    padding: "12px",
    background: "#FFFFFF",
    border: "1px solid #FFFFFF",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "32px",
    color: "#031629",
    outline: "0 !important",
    backgroundColor: "#FFFFFF !important",
    textAlign: "center",
    "&::placeholder": {
      padding: "0",
      fontFamily: "Montserrat",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "32px",
      color: "#0A437C",
      textAlign: "center",
      width: "100%",
    },
  },
  ScanBarcodeDummyTextStyle: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "32px",
    color: "#0A437C",
    verticalAlign: "middle",
  },
  ScanBarcodeTextStyle: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "32px",
    color: "#031629",
    verticalAlign: "middle",
  },
}));
