import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

import { itemDetailsStyle } from "./ItemDetailsStyle";

import TopLeft from "../../assets/svg/ScanProductBox/TopLeft.svg";
import TopRight from "../../assets/svg/ScanProductBox/TopRight.svg";
import BottomLeft from "../../assets/svg/ScanProductBox/BottomLeft.svg";
import BottomRight from "../../assets/svg/ScanProductBox/BottomRight.svg";
import ImageFallback from "../../assets/svg/ImageFallback.svg";

import CarrefourButton from "../../common/CarrefourButton";
import ItemDescriptionDetailDiv from "../Common/ItemDescriptionDetailDiv";

import { NOT_AVAILABLE } from "../../../config/AppConstant";

const DashboardItemDetailsPage = ({
  selectedProductDetailsObj,
  actionOnItemTo,
  itemIdIndex,
  selectedCountItem,
  inputBarCode,
  onChangeInputBarCode,
  onKeyPressBarcode,
  clearBarCode,
  showNewWeightInputDialog,
  totalActionedItems,
  markedOutOfStock,
}) => {
  const itemDetailsClasses = itemDetailsStyle();
  const messagesEndRef = useRef(null);
  const inputBarCodeRef = useRef(null);

  useEffect(() => {
    if (!showNewWeightInputDialog) {
      messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
      inputBarCodeRef?.current?.focus();
    }
  });

  return (
    <div className={itemDetailsClasses.mainDiv}>
      {selectedProductDetailsObj && selectedProductDetailsObj.name != null && selectedProductDetailsObj.name != "" ? (
        <div className={itemDetailsClasses.itemDetailMainDiv}>
          <span className={itemDetailsClasses.itemCountStyle}>Item: </span>
          <div className={itemDetailsClasses.itemDetailsDiv}>
            <span className={itemDetailsClasses.itemNameStyle}>
              {selectedProductDetailsObj.name}{" "}
              {selectedProductDetailsObj.toleranceMaxWeight && selectedProductDetailsObj.toleranceMaxWeight != "" && selectedProductDetailsObj.toleranceMinWeight && selectedProductDetailsObj.toleranceMinWeight != ""
                ? selectedProductDetailsObj.quantity + " g"
                : selectedProductDetailsObj.quantity}
            </span>
            <span className={itemDetailsClasses.itemDescriptionStyle}>---</span>
            {selectedProductDetailsObj && selectedProductDetailsObj.preparationPriority === 1 ? (
              <div className={itemDetailsClasses.carrefournNowButton}>
                <CarrefourButton />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className={itemDetailsClasses.itemDetailsImageDiv}>
            <img
              src={selectedProductDetailsObj.imgUrl}
              className={itemDetailsClasses.itemDetailsImageStyle}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = ImageFallback;
              }}
            />
          </div>
          {selectedCountItem !== 3 ? (
            <button className={itemDetailsClasses.notAvailableButtonStyle} onClick={(event) => actionOnItemTo(event, NOT_AVAILABLE)}>
              <span className={itemDetailsClasses.notAvailableButtonTextStyle}>NOT AVAILABLE</span>
            </button>
          ) : (
            <div className={itemDetailsClasses.emptyNotAvailableDiv}></div>
          )}

          <ItemDescriptionDetailDiv name1={"Item Type"} value1={selectedProductDetailsObj.itemType || "-"} name2={"Cut Type"} value2={selectedProductDetailsObj.cutType || "-"} />
          {selectedProductDetailsObj && selectedProductDetailsObj.toleranceMinWeight != null && selectedProductDetailsObj.toleranceMaxWeight != null ? (
            <ItemDescriptionDetailDiv
              name1={"Weight"}
              value1={selectedProductDetailsObj.quantity + " g" || "-"}
              name2={"Weight Range"}
              value2={selectedProductDetailsObj.toleranceMinWeight + "g - " + selectedProductDetailsObj.toleranceMaxWeight + "g" || "-"}
            />
          ) : (
            <ItemDescriptionDetailDiv
              name1={"Quantity"}
              value1={selectedProductDetailsObj.quantity || "-"}
              name2={"Actioned Items"}
              value2={`${totalActionedItems ? totalActionedItems : itemIdIndex ? itemIdIndex : "0"}/${selectedProductDetailsObj && selectedProductDetailsObj.items ? selectedProductDetailsObj.items.length : "-"}`}
            />
          )}
          {selectedProductDetailsObj && selectedProductDetailsObj.toleranceMinWeight == null && selectedProductDetailsObj.toleranceMaxWeight == null && selectedCountItem == 3 ? (
            <ItemDescriptionDetailDiv name1={"Marked Out of Stock"} value1={markedOutOfStock || "-"} name2={"Quantity Picked"} value2={selectedProductDetailsObj.quantityPicked || "-"} />
          ) : (
            <></>
          )}
          <ItemDescriptionDetailDiv name1={"PLU"} value1={selectedProductDetailsObj.plu || "-"} name2={"Barcode"} value2={selectedProductDetailsObj.orderedBarcode || "-"} />
          {selectedCountItem == 3 ? (
            <>
              <ItemDescriptionDetailDiv name1={"Consignment No"} value1={selectedProductDetailsObj.consignmentCode || "-"} name2={"-"} value2={"-"} />
              <ItemDescriptionDetailDiv name1={"Prepared By"} value1={selectedProductDetailsObj.preparedByName || "-"} name2={"Section"} value2={selectedProductDetailsObj.section || "-"} />
            </>
          ) : (
            <></>
          )}

          {selectedCountItem !== 3 ? (
            <div className={itemDetailsClasses.scanProductDivStyle}>
              <div className={itemDetailsClasses.imageLeftRightDiv}>
                <img src={TopLeft} />
                <img src={TopRight} />
              </div>
              <div className={itemDetailsClasses.scanBarCodeTextDiv}>
                <input
                  autoFocus={true}
                  type="text"
                  placeholder="Scan/Enter Product Barcode (EAN)"
                  value={inputBarCode}
                  id="inputBarCode"
                  name="inputBarCode"
                  className={itemDetailsClasses.ScanBarCodeInputBox}
                  onChange={onChangeInputBarCode}
                  onKeyDown={onKeyPressBarcode}
                  ref={inputBarCodeRef}
                />
                {/* {inputBarCode && inputBarCode != "" ? <span className={itemDetailsClasses.ScanBarcodeTextStyle}>{inputBarCode}</span> : <span className={itemDetailsClasses.ScanBarcodeDummyTextStyle}>Scan/Enter Product Barcode (EAN)</span>} */}
              </div>
              <div className={itemDetailsClasses.imageLeftRightDiv}>
                <img src={BottomLeft} />
                {/* <img src={CancelSvg} onClick={clearBarCode} /> */}
                <img src={BottomRight} />
              </div>
              {inputBarCode != null && inputBarCode != "" ? (
                <>
                  <div className={itemDetailsClasses.imageLeftRightDiv}>
                    <button className={itemDetailsClasses.notAvailableButtonStyle} onClick={clearBarCode}>
                      <span className={itemDetailsClasses.notAvailableButtonTextStyle}>Clear BarCode</span>
                    </button>
                  </div>
                  <div ref={messagesEndRef} />
                </>
              ) : (
                <></>
              )}
              {/* <img src={ScanProduct} className={itemDetailsClasses.scanProductSvgStyle}  /> */}
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className={itemDetailsClasses.loadingMainDiv}>
          <div className={itemDetailsClasses.loadingMainDivInner}>
            <span className={itemDetailsClasses.NoDataAvailableStyle}>No Item Selected</span>
          </div>
        </div>
      )}
    </div>
  );
};

DashboardItemDetailsPage.propTypes = {
  selectedProductDetailsObj: PropTypes.object.isRequired,
  actionOnItemTo: PropTypes.func.isRequired,
  itemIdIndex: PropTypes.any.isRequired,
  selectedCountItem: PropTypes.number.isRequired,
  inputBarCode: PropTypes.any.isRequired,
  onChangeInputBarCode: PropTypes.func.isRequired,
  onKeyPressBarcode: PropTypes.func.isRequired,
  clearBarCode: PropTypes.func.isRequired,
  showNewWeightInputDialog: PropTypes.bool.isRequired,
  totalActionedItems: PropTypes.any,
  markedOutOfStock: PropTypes.any,
};

export default React.memo(DashboardItemDetailsPage);
