import { createMuiTheme } from "@material-ui/core/styles";

export const COLORS = {
  PRIMARY_MAIN: "#0E5AA7",
  PRIMARY_LIGHT: "rgba(14, 90, 167, 0.2)",
  TEXT_PRIMARY: "#252525",
  TRANSPARENT_BLUE: "rgba(10, 67, 124, 0.05)",
  WHITE: "#FFFFFF",
  TRANSPARENT: "transparent",
};

const theme = createMuiTheme({
  typography: {
    fontFamily: ['"Montserrat"', "Open Sans"].join(","),
  },

  overrides: {
    MuiCheckbox: {
      root: {
        padding: 0,
      },
    },
  },
});

export default theme;
