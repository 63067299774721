import { createSlice } from "@reduxjs/toolkit";
import { CURRENT_COUNTRY_CODE, POS_CODE, CATEGORY_CODE } from "../AppConstant";

const initialState = {
  currentCountryCode: localStorage.getItem(CURRENT_COUNTRY_CODE) ? localStorage.getItem(CURRENT_COUNTRY_CODE) : "",
  currentPosCode: localStorage.getItem(POS_CODE) ? localStorage.getItem(POS_CODE) : "",
  currentCategoryCode: localStorage.getItem(CATEGORY_CODE) ? localStorage.getItem(CATEGORY_CODE) : "",
};

const appDataConfigSlice = createSlice({
  name: "appDataConfig",
  initialState,
  reducers: {
    changeCountryCode: (state, action) => {
      state.currentCountryCode = action.payload;
    },
    changePosCode: (state, action) => {
      state.currentPosCode = action.payload;
    },
    changeCategoryCode: (state, action) => {
      state.currentCategoryCode = action.payload;
    },
    resetAppDataConfig: () => initialState,
  },
});

export const { changeCountryCode, changePosCode, changeCategoryCode, resetAppDataConfig } = appDataConfigSlice.actions;

export default appDataConfigSlice.reducer;
