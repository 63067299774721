import React from "react";
import PropTypes from "prop-types";

import { useStyles } from "../Styles/DialogSingleInputBoxStyle";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

import DialogExternalTextAlert from "../../assets/svg/DialogExternalTextAlert.svg";
import EnterWeightIcon from "../../assets/svg/EnterWeightIcon.svg";
import { DEFAULT_TEXT, PLACEHOLDER_TEXT, WEIGHT_TEXT } from "../../../config/AppConstant";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogSingleInputBox = ({ open, onClose, headerText, placeholderText, value, proceedAction, onChange, externalText }) => {
  const classes = useStyles();
  const scroll = "paper";

  return (
    <Dialog open={open} onClose={onClose} scroll={scroll} classes={{ paper: classes.paper, scrollPaper: classes.scrollPaper }} TransitionComponent={Transition}>
      <DialogContent dividers={scroll === "paper"} className={classes.dialogContentStyle}>
        <div className={classes.dialogContentDiv}>
          <div className={classes.dialogContentFlexStart}>
            {headerText === WEIGHT_TEXT ? <img src={EnterWeightIcon} className={classes.externalImgStyle} /> : <></>}
            <span className={classes.dialogDivTitleNormal}>{headerText || DEFAULT_TEXT}</span>
          </div>
        </div>

        <div className={classes.mainDivColumn}>
          <input type="text" autoFocus pattern="[0-9]+" className={classes.NewUserInputBox} id="inputBarCode" placeholder={placeholderText || PLACEHOLDER_TEXT} value={value} required onChange={onChange} name={value} />
        </div>
        {externalText && externalText != "" ? (
          <div className={classes.dialogExternalTextDiv}>
            <img src={DialogExternalTextAlert} className={classes.externalImgStyle} />
            <span className={classes.externalTextStyle}>{externalText || "n/a"}</span>
          </div>
        ) : (
          <></>
        )}
      </DialogContent>
      <DialogActions classes={{ root: classes.DialogActionsStyle }}>
        <button className={classes.resetButton} onClick={onClose}>
          <span className={classes.resetButtonText}>CANCEL</span>
        </button>
        <button className={classes.successButton} onClick={proceedAction}>
          <span className={classes.successButtonText}>PROCEED</span>
        </button>
      </DialogActions>
    </Dialog>
  );
};

DialogSingleInputBox.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  placeholderText: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  proceedAction: PropTypes.func.isRequired,
  externalText: PropTypes.string,
};

export default React.memo(DialogSingleInputBox);
