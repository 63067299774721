import { makeStyles } from "@material-ui/core";

export const itemListStyle = makeStyles(() => ({
  listMainDiv: {
    width: "100%",
    height: "calc(100vh - 254px)",
    background: "#FCFCFC",
    backgroundColor: "#FCFCFC",
    border: "1px solid #D2D2D2",
    boxSizing: "border-box",
    borderRadius: "8px",
  },
  listDivTopMenu: {
    width: "100%",
    height: "66px",
    display: "flex",
    justifyContent: "space-between",
    margin: 0,
    padding: "24px",
    background: "#FFFFFF",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
  },
  listDivTopMenuLeftL: {
    margin: 0,
    padding: 0,
    width: "50%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-start",
  },
  listDivTopMenuRight: {
    margin: 0,
    padding: 0,
    width: "50%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  listDivTopMenuTextStyle: {
    marginTop: "auto",
    marginBottom: "auto",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#393939",
  },
  reloadIconStyle: {
    width: "20px",
    height: "20px",
    cursor: "pointer",
  },

  listCategoryDiv: {
    width: "100%",
    height: "50px",
    display: "flex",
    justifyContent: "flex-end",
    margin: 0,
    padding: "0px",
    background: "rgba(230, 230, 230, 0.4)",
    backgroundColor: "rgba(230, 230, 230, 0.4)",
  },
  // categoryButtonSelected: {
  //   height: "30px",
  //   width: "auto",
  //   marginRight: "12px",
  //   padding: "0px 10px",
  //   background: "#0E5AA7",
  //   border: "1px solid #0E5AA7",
  //   borderRadius: "8px",
  //   outline: "0 !important",
  //   marginTop: "auto",
  //   marginBottom: "auto",
  // },
  // categoryButtonSelectedText: {
  //   fontFamily: "Montserrat",
  //   fontStyle: "normal",
  //   fontWeight: "600",
  //   fontSize: "12px",
  //   lineHeight: "15px",
  //   textAlign: "center",
  //   color: "#FFFFFF",
  // },
  // categoryButton: {
  //   height: "30px",
  //   width: "auto",
  //   marginRight: "12px",
  //   padding: "0px 10px",
  //   background: "#FFFFFF",
  //   border: "1px solid #E6E6E6",
  //   borderRadius: "8px",
  //   outline: "0 !important",
  //   marginTop: "auto",
  //   marginBottom: "auto",
  // },
  // categoryButtonText: {
  //   marginTop: "auto",
  //   marginBottom: "auto",
  //   fontFamily: "Montserrat",
  //   fontStyle: "normal",
  //   fontWeight: "600",
  //   fontSize: "12px",
  //   lineHeight: "15px",
  //   textAlign: "center",
  //   color: "#666666",
  // },

  productListDiv: {
    width: "auto",
    height: "calc(100vh - 400px)",
    overflow: "auto",
    margin: "14px 14px 14px 24px",
    background: "#FFFFFF",
    borderRadius: "8px",
  },
  productDetailsMainLastDiv: {
    height: "170px",
    maxHeight: "170px",
    minHeight: "170px",
    width: "25%",
    display: "grid",
    float: "left",
    padding: 0,
    margin: 0,
    paddingRight: "0px",
    marginBottom: "16px",
  },
  productDetailsMainDiv: {
    height: "170px",
    maxHeight: "170px",
    minHeight: "170px",
    width: "25%",
    display: "grid",
    float: "left",
    padding: 0,
    margin: 0,
    paddingRight: "16px",
    marginBottom: "16px",
  },
  productDetailsActiveDiv: {
    width: "100%",
    height: "100%",
    padding: "15px",
    background: "#FFFFFF",
    border: "1px solid #0E5AA7",
    borderRadius: "8px",
  },
  productDetailsDiv: {
    width: "100%",
    height: "100%",
    padding: "15px",
    background: "#FFFFFF",
    border: "1px solid #E6E6E6",
    borderRadius: "8px",
  },
  loadingMainDiv: {
    height: "100%",
    width: "100%",
    display: "table",
  },
  loadingMainDivInner: {
    height: "100%",
    width: "100%",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
  },
  itemDetailsTopDiv: {
    display: "grid",
    height: "115px",
    maxHeight: "115px",
  },
  itemDetailsImageStyle: {
    width: "80px",
    height: "80px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  itemDetailsNameStyle: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#252525",
    marginTop: "5px",
  },
  itemDetailsBottomDiv: {
    width: "100%",
    height: "24px",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "5px",
  },
  itemDetailsQuantityStyle: {
    marginTop: "auto",
    marginBottom: "auto",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "12px",
    color: "#252525",
  },
  textFontStyle: {
    marginTop: "auto",
    marginBottom: "auto",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#252525",
  },
  tablePaginationFooterStyle: {
    margin: 0,
    padding: 0,
    left: 0,
    bottom: 0,
    marginTop: "auto",
    marginBottom: "auto",
    height: "40px",
    minHeight: "40px",
    maxHeight: "40px",
    overflow: "hidden",
    background: "#F5F5F5",
    backgroundColor: "#F5F5F5",
    border: "none",
  },
  tablePaginationStyle: {
    height: "50px",
  },
  dividerStyle: {
    margin: "0px 12px 0px 12px",
    padding: 0,
    height: "20px",
  },
  pageautoRefreshSpan: {
    margin: 0,
    padding: 0,
    marginTop: "auto",
    marginBottom: "auto",
    fontFamily: "Montserrat",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#000000",
  },
}));
