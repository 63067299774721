import { makeStyles } from "@material-ui/core/styles";

const loginPageStyles = makeStyles(() => ({
  LoginPageDivTable: {
    display: "table",
    height: "calc(100vh - 0px)",
    width: "calc(100vw - 0px)",
    textAlign: "center",
    padding: "20px",
    overflow: "hidden",
    background: "rgba(14, 90, 167, 0.1)",
  },
  LoginPageDivTableInner: {
    display: "table-cell",
    height: "100%",
    width: "100%",
    verticalAlign: "middle",
  },
  LoginPageDiv: {
    width: "500px",
    height: "500px",
    margin: "auto",
    padding: "20px 52px",
    backgroundColor: "#FFFFFF",
    borderStyle: "inset",
    borderRadius: "8px",
    outline: "0 !important",
  },
  LoadingDivTable: {
    display: "table",
    height: "100%",
    width: "100%",
    textAlign: "center",
  },
  logoImageStyle: {
    margin: "0  0 14% 0",
    width: "100%",
    padding: "0 20%",
  },
  mainHeaderDiv: {
    marginBottom: "0px",
    textAlign: "left",
  },
  mainHeaderSpan: {
    fontFamily: "Montserrat",
    fontSize: "28px",
    fontWeight: "500",
    lineHeight: "32px",
    color: "#252525",
  },
  userDetailsInputBox: {
    display: "block",
    width: "100%",
    height: "46px",
    padding: "12px",
    background: "#FFFFFF",
    border: "1px solid #B2B2B2",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#252525",
    outline: "0 !important",
    backgroundColor: "#FFFFFF !important",
    "&::placeholder": {
      padding: "0",
      color: "#B2B2B2",
      textAlign: "left",
      width: "100%",
    },
  },
  signInButton: {
    marginTop: "18px",
    width: "100%",
    height: "46px",
    background: "#0E5AA7",
    border: "1px solid #0E5AA7",
    borderRadius: "8px",
    outline: "0 !important",
  },
  signInButtonTestStyle: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "17px",
    color: "#FFFFFF",
  },
  toastStyle: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    lineHeight: "17px",
    borderRadius: "8px",
    hideProgressBar: false,
  },
  poweredByLogoImageStyle: {
    width: "300px",
    float: "right",
    marginRight: "-50px",
  },
  // signInButton: {
  //   margin: "117px 0",
  //   width: "100%",
  //   height: "62px",
  //   textAlign: "center",
  //   background: "none",
  //   border: "1px solid #737373",
  //   color: "#737373",
  //   borderRadius: "8px",
  //   outline: "0 !important",
  //   "&:disabled": {
  //     background: "#E6E6E6",
  //     border: "1px solid #E6E6E6",
  //     pointerEvents: "none",
  //     cursor: "not-allowed",
  //     color: "#B2B2B2",
  //   },
  // },
  MicrosoftLogoStyle: {
    marginRight: "12px",
  },
  MicrosoftTextStyle: {
    fontFamily: "Montserrat",
    fontSize: "15px",
    fontWeight: "600",
    lineHeight: "8px",
    color: "#252525",
    "&:disabled": {
      color: "#B2B2B2",
    },
  },
  errorsStyleDiv: {
    position: "fixed",
    width: "390px",
    height: "60px",
    background: "#f8d7da",
    border: "1px solid #f5c6cb",
    borderRadius: "8px",
    outline: "0 !important",
    marginTop: "7%",
    marginLeft: "58%",
  },
  errorsTextStyle: {
    marginTop: "auto",
    marginBottom: "auto",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "8px",
    color: "#721c24",
  },
}));

export default loginPageStyles;
