import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { APP_ROUTES } from "../../config/AppConstant";
import CarrefourLogoWhite from "../assets/svg/CarrefourLogoWhite.svg";
import DividerText from "../common/DividerText.js";
import loginPageStyles from "./LoginPageStyles";
import { loginUser } from "./redux/userSlice";


const UserLoginPage = () => {
    const classes = loginPageStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { isLoggedIn, loading, loginError } = useSelector((state) => state.user);

    const [userName, setUserName] = useState("");
    const [userPassword, setUserPassword] = useState("");


    useEffect(() => {
        if (isLoggedIn) {
            history.push(APP_ROUTES.DASHBOARD);
        }

        if (loginError && loginError["errors"] !== undefined) {
            const { errors } = loginError;
            if (Array.isArray(errors) && errors.length != null && errors.length > 0) {
                const { errorMessage } = errors[0];
                toastError(errorMessage);
            } else {
                toastError(errors);
            }
        }
    }, [isLoggedIn, history, loginError]);

    function toastError(message) {
        toast.error(message, { className: classes.toastStyle, hideProgressBar: false });
    }

    const handleChange = (event) => {
        event.preventDefault();
        if (event && event.target) {
            const { name, value } = event.target;
            if (name === "userName") {
                setUserName(value);
            } else {
                setUserPassword(value);
            }
        }
    };

    function checkFormValid() {
        if (userName === "") {
            return true;
        }

        if (userPassword === "") {
            return true;
        }

        return false;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!checkFormValid()) {
            const userData = {
                userName,
                password: userPassword,
            };
            dispatch(loginUser(userData));
        }
    };


    return (
        <div className={classes.LoginPageDivTable}>
            <div className={classes.LoginPageDivTableInner}>
                <div className={classes.LoginPageDiv}>
                    {loading ? (
                        <div className={classes.LoadingDivTable}>
                            <div className={classes.LoginPageDivTableInner}>
                                <CircularProgress disableShrink thickness={2} size={30} />
                            </div>
                        </div>
                    ) : (
                        <>
                            <img src={CarrefourLogoWhite} className={classes.logoImageStyle}></img>

                            <div className={classes.mainHeaderDiv}>
                                <span className={classes.mainHeaderSpan}>Login to your account</span>
                            </div>

                            <form onSubmit={handleSubmit}>
                                <DividerText> Username </DividerText>
                                <input type="text" className={classes.userDetailsInputBox} id="userName" name="userName" placeholder="Name" value={userName} required onChange={handleChange} />
                                <DividerText> Password </DividerText>
                                <input type="text" className={classes.userDetailsInputBox} id="userPassword" name="userPassword" placeholder="Password" value={userPassword} required onChange={handleChange} />
                                <button className={classes.signInButton} type="submit" disabled={loading}>
                                    <span className={classes.signInButtonTestStyle}>{loading ? "Loading" : "Sign in"}</span>
                                </button>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserLoginPage;
