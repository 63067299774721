import Fetch from "./Fetch";
import { handleError, handleResponseLogin, handleResponse } from "./apiUtils";
import { _apiItemCategoryListURL, _apiUserManagement } from "./FetchUtils";

export function login(userData) {
  const url = `${_apiUserManagement}/auth/signin`;
  return Fetch.create(url, userData).then(handleResponseLogin).catch(handleError);
}

export function fetchAllCountries() {
  const url = `${_apiUserManagement}/countries`;
  return Fetch.get(url).then(handleResponseLogin).catch(handleError);
}

export function fetchPosList(id) {
  const url = `${_apiUserManagement}/pos/selected-countries?countryIds=${id}`;
  return Fetch.get(url).then(handleResponse).catch(handleError);
}

export function loginByMicrosoftAPI(data) {
  return request(_apiUserManagement + "/v2/auth/signin", data)
    .then(handleResponseLogin)
    .catch(handleError);
}

async function request(url, params) {
  const options = {
    method: "POST",
    headers: {
      token: params.token,
    },
  };
  const response = await fetch(url, options);
  return response;
}

export function fetchCategoryList() {
  const url = `${_apiItemCategoryListURL}/itemcategories`;
  return Fetch.openGet(url).then(handleResponse).catch(handleError);
}

