import { makeStyles } from "@material-ui/core";

export const commonErrorStyles = makeStyles(() => ({
  loadingMainDiv: {
    height: "calc(100vh - 0px)",
    width: "calc(100vw - 0px)",
    display: "table",
  },
  loadingMainDivInner: {
    height: "100%",
    width: "100%",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
  },
  errorNameStyle: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#252525",
    marginTop: "5px",
  },
  logoutButtonStyle: {
    height: "30px",
    width: "auto",
    marginRight: "12px",
    padding: "0px 10px",
    background: "#FFFFFF",
    border: "1px solid #E6E6E6",
    borderRadius: "8px",
    outline: "0 !important",
    marginTop: "auto",
    marginBottom: "auto",
  },
  logoutButtonTextStyle: {
    marginTop: "auto",
    marginBottom: "auto",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "15px",
    textAlign: "center",
    color: "#666666",
  },
}));
