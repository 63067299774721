import { makeStyles } from "@material-ui/core";

export const itemCountStyle = makeStyles(() => ({
  countMainDiv: {
    width: "100%",
    padding: "0 0 20px 0",
    display: "flex",
    justifyContent: "space-between",
  },
  countDiv: {
    height: "50px",
    width: "25%",
    display: "grid",
    float: "left",
    padding: 0,
    margin: 0,
    paddingRight: "20px",
    cursor: "pointer",
    "&:last-child": {
      padding: 0,
      paddingLeft: "0px",
    },
  },

  countBoxActive: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    padding: "10px",
    background: "#0A437C",
    backgroundColor: "#0A437C",
    border: "1px solid rgba(10, 67, 124, 0.2)",
    borderRadius: "8px",
  },
  countBoxActiveText: {
    marginTop: "auto",
    marginBottom: "auto",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: "center",
    color: "#FFFFFF",
  },
  countBoxActiveTextBold: {
    marginTop: "auto",
    marginBottom: "auto",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "29px",
    textAlign: "center",
    color: "#FFFFFF",
  },

  countBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    padding: "10px",
    background: "rgba(10, 67, 124, 0.05)",
    backgroundColor: "rgba(10, 67, 124, 0.05)",
    border: "1px solid rgba(10, 67, 124, 0.2)",
    borderRadius: "8px",
  },
  countBoxText: {
    marginTop: "auto",
    marginBottom: "auto",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: "center",
    color: "#2D2D2D",
  },
  countBoxTextBold: {
    marginTop: "auto",
    marginBottom: "auto",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "29px",
    textAlign: "center",
    color: "#2D2D2D",
  },
}));
