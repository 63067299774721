export const CURRENT_COUNTRY_CODE = "currentCountry";
export const POS_CODE = "posCode";
export const CATEGORY_CODE = "categoryCode";

export const API_RESPONSE_STATUS = {
  IDLE: "idle",
  LOADING: "loading",
  SUCCEEDED: "succeeded",
  FAILED: "failed",
};

export const APP_ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  INVALID_USER_PAGE: "/invalidUser",
};

export const NOT_AVAILABLE = "notAvailable";
export const SCAN_PRODUCT = "scanProduct";

export const DEFAULT_TEXT = "Enter Value";
export const PLACEHOLDER_TEXT = "Enter value";

export const BAR_CODE_TEAXT = "Product Bar Code";
export const BAR_CODE_PLACEHOLDER_TEXT = "Enter Bar Code";

export const WEIGHT_TEXT = "ENTER weight (gms)";

export const BAR_CODE_LENGTH_ERROR = "Entered BarCode Length is not valid";

export const PIECES = "pieces";
export const PIECE = "piece";

export const REFRESH_DASHBOARD_SCREEN_INTERVAL = 300000;
