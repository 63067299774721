import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

import TablePagination from "@material-ui/core/TablePagination";

import { itemListStyle } from "./ItemListStyle";
import reloadIcon from "../../assets/svg/ReloadIcon.svg";
import CarrefourButton from "../../common/CarrefourButton";
import OutOfStockText from "../../common/OutOfStockText";
import TablePaginationActions from "../../common/TablePaginationActions";
import ImageFallback from "../../assets/svg/ImageFallback.svg";
import TopMenuDivider from "../../assets/svg/TopMenuDivider.svg";

const DashboardItemsListPage = ({
  counterItemData,
  pendingItemCount,
  topMenuSearchObj,
  handleChangePage,
  selectedCountItem,
  selectedProductDetailsIndex,
  updateSelectedProductDetailsObj,
  reloadCounterItemsReport,
  minuteLeft,
  secondLeft,
  countDownInterval,
}) => {
  const itemListClasses = itemListStyle();
  const messagesEndRef = useRef(null);

  const [isDataLoading, setIsDataLoading] = useState(true);

  useEffect(() => {
    messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [isDataLoading]);

  useEffect(() => {
    setTimeout(() => {
      setIsDataLoading(false);
    }, 2000);
  }, []);

  return (
    <div className={itemListClasses.listMainDiv}>
      <div className={itemListClasses.listDivTopMenu}>
        <div className={itemListClasses.listDivTopMenuLeft}>
          {selectedCountItem === 3 ? <span className={itemListClasses.listDivTopMenuTextStyle}> Completed Items </span> : <span className={itemListClasses.listDivTopMenuTextStyle}> Pending Items ({pendingItemCount})</span>}
        </div>
        <div className={itemListClasses.listDivTopMenuRight}>
          {countDownInterval != null && countDownInterval > 0 && minuteLeft != null && secondLeft != null ? (
            <>
              <span className={itemListClasses.pageautoRefreshSpan}>
                Auto refresh in {minuteLeft | ""} Min : {secondLeft | ""} Sec
              </span>
              <img src={TopMenuDivider} className={itemListClasses.dividerStyle} />
            </>
          ) : (
            <></>
          )}

          <img src={reloadIcon} className={itemListClasses.reloadIconStyle} onClick={reloadCounterItemsReport} />
        </div>
      </div>

      <div className={itemListClasses.listCategoryDiv}>
        <table className={itemListClasses.tablePaginationStyle}>
          <tbody className={itemListClasses.tablePaginationStyle}>
            <tr className={itemListClasses.tablePaginationStyle}>
              <TablePagination
                classes={{ root: itemListClasses.textFontStyle, caption: itemListClasses.textFontStyle, toolbar: itemListClasses.tablePaginationFooterStyle }}
                rowsPerPageOptions={[]}
                labelRowsPerPage=""
                count={pendingItemCount}
                rowsPerPage={topMenuSearchObj.recordsPerPage}
                page={counterItemData != null && counterItemData.length != null && counterItemData.length > 0 ? topMenuSearchObj.pageNumber : 0}
                onChangePage={handleChangePage}
                ActionsComponent={(subProps) => <TablePaginationActions {...subProps} count={pendingItemCount} onChangePage={handleChangePage} page={topMenuSearchObj.pageNumber} rowsPerPage={topMenuSearchObj.recordsPerPage} />}
                className={itemListClasses.tablePaginationFooterStyle}
              />
            </tr>
          </tbody>
        </table>

        {/* <button className={itemListClasses.categoryButtonSelected}>
          <span className={itemListClasses.categoryButtonSelectedText}>All</span>
        </button>

        <button className={itemListClasses.categoryButton}>
          <span className={itemListClasses.categoryButtonText}>Chicken</span>
        </button>
    */}
      </div>

      <div className={itemListClasses.productListDiv}>
        {counterItemData.length != null && counterItemData.length > 0 ? (
          counterItemData.map((item, itemIndex) => (
            <div
              key={"ProductList-" + itemIndex}
              className={(itemIndex + 1) % 4 == 0 ? itemListClasses.productDetailsMainLastDiv : itemListClasses.productDetailsMainDiv}
              onClick={(event) => updateSelectedProductDetailsObj(event, itemIndex)}
              ref={selectedProductDetailsIndex === itemIndex ? messagesEndRef : null}
            >
              <div className={selectedProductDetailsIndex === itemIndex ? itemListClasses.productDetailsActiveDiv : itemListClasses.productDetailsDiv}>
                <div className={itemListClasses.itemDetailsTopDiv}>
                  <img
                    src={item.imgUrl}
                    className={itemListClasses.itemDetailsImageStyle}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = ImageFallback;
                    }}
                  />
                  <span className={itemListClasses.itemDetailsNameStyle}>{item.name || "----"}</span>
                </div>
                <div className={itemListClasses.itemDetailsBottomDiv}>
                  <span className={itemListClasses.itemDetailsQuantityStyle}>
                    {item.toleranceMaxWeight && item.toleranceMaxWeight != "" && item.toleranceMinWeight && item.toleranceMinWeight != "" ? item.quantity + " g" : item.quantity}
                  </span>
                  {item.preparationPriority && item.preparationPriority === 1 ? <CarrefourButton /> : <></>}
                  {item.prepared && !item.isAvailable ? <OutOfStockText /> : <></>}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className={itemListClasses.loadingMainDiv}>
            <div className={itemListClasses.loadingMainDivInner}>
              <span className={itemListClasses.itemDetailsNameStyle}>No Data Available</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

DashboardItemsListPage.propTypes = {
  counterItemData: PropTypes.arrayOf(PropTypes.object).isRequired,
  pendingItemCount: PropTypes.any.isRequired,
  topMenuSearchObj: PropTypes.object.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  selectedCountItem: PropTypes.number.isRequired,
  selectedProductDetailsIndex: PropTypes.number.isRequired,
  updateSelectedProductDetailsObj: PropTypes.func.isRequired,
  reloadCounterItemsReport: PropTypes.func.isRequired,
  minuteLeft: PropTypes.any,
  secondLeft: PropTypes.any,
  countDownInterval: PropTypes.any,
};

export default React.memo(DashboardItemsListPage);
