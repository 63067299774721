import React, { useEffect, useState } from "react";
import moment from "moment";

import * as DashboardService from "./DashboardService/DashboardService";
import { dashboardStyles } from "./DashboardStyles/DashboardStyles";

import { useSelector } from "react-redux";

import { TopMenuSearchData, ItemCountListData } from "./Common/Dashboard";
import TopMenuPage from "../TopMenu/TopMenuPage";

import DashboardTopSearchBarPage from "./TopSearchBar/DashboardTopSearchBarPage";
import DashboardItemsCountPage from "./DashboardItemsCount/DashboardItemsCountPage";
import DashboardItemsListPage from "./DashboardItemsList/DashboardItemsListPage";
import DashboardItemDetailsPage from "./DashboardItemDetails/DashboardItemDetailsPage";
import DialogSingleInputBox from "../common/Dialog/DialogSingleInputBox";

import { toast } from "react-toastify";
import { CircularProgress } from "@material-ui/core";
import { NOT_AVAILABLE, SCAN_PRODUCT, WEIGHT_TEXT, BAR_CODE_LENGTH_ERROR, PIECES, PIECE, REFRESH_DASHBOARD_SCREEN_INTERVAL } from "../../config/AppConstant";

const DashboardPage = () => {
  const dashboardClasses = dashboardStyles();
  const dateFormat = "YYYY-MM-DD";

  const { currentCountryCode, currentPosCode, currentCategoryCode } = useSelector((state) => state.appDataConfig);

  const [isTopLevelLoading, setIsTopLevelLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [slotList, setSlotList] = useState([]);

  const [topMenuSearchObj, setTopMenuSearchObj] = useState(TopMenuSearchData);
  const [delLabel, setDelLabel] = useState("");

  const [selectedCountItem, setSelectedCountItem] = useState(0); // DashboardItemsCountPage
  const [itemListData, setItemListData] = useState(ItemCountListData); // DashboardItemsCountPage

  const [counterItemData, setCounterItemData] = useState([]); // DashboardItemsListPage
  const [pendingItemCount, setPendingItemCount] = useState(0); // DashboardItemsListPage

  const [selectedProductDetailsIndex, setSelectedProductDetailsIndex] = useState(0); //DashboardItemsListPage
  const [selectedProductDetailsObj, setSelectedProductDetailsObj] = useState({}); //DashboardItemDetailsPage
  const [itemIdIndex, setItemIdIndex] = useState(0); //DashboardItemDetailsPage and Prepare API

  const [inputBarCode, setInputBarCode] = useState("");

  const [externalDialogText, setExternalDialogText] = useState("");
  const [newWeight, setNewWeight] = useState("");
  const [showNewWeightInputDialog, setShowNewWeightInputDialog] = useState(false);

  const [prepareDataObj, setPrepareData] = useState({});

  const [refreshInterval, setRefreshInterval] = useState(0); //Auto-Refresh
  const [minuteLeft, setMinuteLeft] = useState(null); //Auto-Refresh
  const [secondLeft, setSecondLeft] = useState(null); //Auto-Refresh
  const [countDownInterval, setCountDownInterval] = useState(0); //Auto-Refresh

  const [markedOutOfStock, setMarkedOutOfStock] = useState(0);
  const [totalActionedItems, setTotalActionedItems] = useState(0);

  const [itemSource, setItemSource] = useState("");

  function toastWarn(message) {
    toast.warn(message, { className: dashboardClasses.toastStyle, hideProgressBar: false });
  }

  function toastError(message) {
    toast.error(message, { className: dashboardClasses.toastStyle, hideProgressBar: false });
  }

  useEffect(() => {
    setDelLabel("");
    setInputBarCode("");
    setRefreshInterval(0);
  }, [currentCountryCode]);

  useEffect(() => {
    if (currentPosCode != null && currentPosCode != "" && topMenuSearchObj != null && topMenuSearchObj.store != null && currentPosCode != topMenuSearchObj.store) {
      setRefreshInterval(0);
      setIsTopLevelLoading(true);
      setDelLabel(moment(new Date()).format(dateFormat));
      setTopMenuSearchObj((prevObj) => ({
        ...prevObj,
        store: currentPosCode,
        date: moment(new Date()).format(dateFormat),
        pageNumber: 0,
        slotId: "",
        slotStartTime: "",
        slotEndTime: "",
      }));
      fetchSlotListBasedOnPosCode();
    }
  }, [currentPosCode]);

  useEffect(() => {
    if (currentCategoryCode != null && currentCategoryCode != "" && topMenuSearchObj != null && topMenuSearchObj.category != null && currentCategoryCode != topMenuSearchObj.category) {
      setRefreshInterval(0);
      resetView();
      localStorage.removeItem("selectedProductDetailsIndex");
      setTopMenuSearchObj((prevObj) => ({
        ...prevObj,
        category: currentCategoryCode,
        date: moment(new Date()).format(dateFormat),
        pageNumber: 0,
      }));
    }
  }, [currentCategoryCode]);

  useEffect(() => {
    const errorObject = checkIsTopMenuSearchObjValid();
    if (Object.keys(errorObject).length === 0) {
      setRefreshInterval(0);
      fetchCounterItemsData();
    }
  }, [topMenuSearchObj]);

  useEffect(() => {
    setRefreshInterval(0);
    setCounterItemData([]);
    setPendingItemCount(0);
    setSelectedCountItem(0);
    if (currentPosCode != null && currentPosCode != "" && delLabel != null && delLabel != "") {
      const listData = [...itemListData];
      listData.map((item) => {
        item.count = 0;
      });
      setItemListData(listData);
      resetSelectedItemIndex();
      resetView();
      fetchSlotListBasedOnPosCode();
    } else {
      setIsTopLevelLoading(false);
    }
  }, [delLabel]);

  function resetView() {
    setSelectedProductDetailsObj({});
  }

  function resetSelectedItemIndex() {
    setItemIdIndex(0);
    setSelectedProductDetailsIndex(0);
    localStorage.removeItem("slotData");
    localStorage.removeItem("selectedProductDetailsIndex");
  }

  function checkIsTopMenuSearchObjValid() {
    const { date, category, store, orderType, prepared } = topMenuSearchObj;
    const errorsObject = {};

    // const NO_SLOT_ID = "No Slot Data Found.";
    const NO_DATE_INFO = "No Date Data Found.";
    const NO_CATEGORY_INFO = "No Category Data Found";
    const NO_STORE_INFO = "No Store Code Data Found";
    const NO_ORDER_TYPE_INFO = "No Order Type Data Found";

    // if (!slotId || slotId === "") errorsObject.slot = NO_SLOT_ID;
    // if (!slotEndTime || slotEndTime === "") errorsObject.slot = NO_SLOT_ID;
    // if (!slotStartTime || slotStartTime === "") errorsObject.slot = NO_SLOT_ID;

    if (!date || date === "") errorsObject.date = NO_DATE_INFO;

    if (!category || category === "") errorsObject.category = NO_CATEGORY_INFO;
    if (!store || store === "") errorsObject.store = NO_STORE_INFO;
    if (!orderType || orderType === "") errorsObject.orderType = NO_ORDER_TYPE_INFO;

    if (typeof prepared !== "boolean") errorsObject.prepared = "Error while Calculating Prepared Status";

    return errorsObject;
  }

  function fetchSlotListBasedOnPosCode() {
    if (currentPosCode != null && currentPosCode != "" && delLabel != null && delLabel != "") {
      resetView();
      const listData = [...itemListData];
      listData.map((item) => {
        item.count = 0;
      });
      setItemListData(listData);
      setSlotList([]);
      const obj = {
        posCode: currentPosCode,
        deliveryDate: delLabel,
      };
      callSlotListApi(obj);
    }
  }

  function callSlotListApi(obj) {
    DashboardService.getSlotList(
      obj,
      (response) => {
        if (response && response.slots && Array.isArray(response.slots) && response.slots.length != null && response.slots.length > 0) {
          response.slots.map((item) => {
            item.slotName = item.slotStartTime + " - " + item.slotEndTime;
          });
          setSlotList(response.slots);
          updateTopMenuObjWithSlotDetails(response.slots);
        } else {
          // resetSelectedItemIndex();
          toastWarn("No Slot found.");
          setTopMenuSearchObj((prevObj) => ({
            ...prevObj,
            slotId: "",
            slotStartTime: "",
            slotEndTime: "",
          }));
        }
        setIsTopLevelLoading(false);
      },
      (err) => {
        resetSelectedItemIndex();
        setIsTopLevelLoading(false);
        console.error(err);
      }
    );
  }

  function updateTopMenuObjWithSlotDetails(data) {
    let slotData = data[0];
    if (localStorage.getItem("slotData")) {
      const { slotStartTime, slotEndTime } = JSON.parse(localStorage.getItem("slotData"));
      if (slotStartTime && slotEndTime) {
        data.map((slot) => {
          if (slot.slotStartTimeInMillis == slotStartTime && slot.slotEndTimeInMillis == slotEndTime) {
            slotData = slot;
          }
        });
      }
    }
    updateTopMenuObjWithSlot(slotData);
  }

  function updateTopMenuObjWithSlot(slotData) {
    if (slotData != null && typeof slotData == "object") {
      const { slotName = "", slotStartTimeInMillis = 0, slotEndTimeInMillis = 0 } = slotData;
      localStorage.setItem(
        "slotData",
        JSON.stringify({
          slotId: slotName,
          slotStartTime: slotStartTimeInMillis,
          slotEndTime: slotEndTimeInMillis,
        })
      );
      setTopMenuSearchObj((prevObj) => ({
        ...prevObj,
        slotId: slotName,
        slotStartTime: slotStartTimeInMillis,
        slotEndTime: slotEndTimeInMillis,
      }));
    }
  }

  function fetchCounterItemsData() {
    setIsLoading(true);
    setIsTopLevelLoading(false);
    setCounterItemData([]);
    setPendingItemCount(0);
    const listData = [...itemListData];
    listData.map((item) => {
      item.count = 0;
    });
    setItemListData(listData);

    fetchCountData();
    fetchItemsData();
  }

  function fetchCountData() {
    const { slotStartTime, slotEndTime, category, store, orderType, prepared } = topMenuSearchObj;

    const countObj = {
      category,
      slotStartTime,
      slotEndTime,
      store,
      deliveryDate: delLabel,
      orderType,
      prepared,
    };

    callApiToFetchCounterItemCountData(countObj);
  }

  function fetchItemsData() {
    const { slotStartTime, slotEndTime, category, store, orderType, prepared, pageNumber, recordsPerPage } = topMenuSearchObj;

    const itemObj = {
      slotStartTime,
      slotEndTime,
      category,
      store,
      orderType,
      prepared,
      pageNumber,
      recordsPerPage,
      deliveryDate: delLabel,
    };

    callApiToFetchCounterItemReport(itemObj);
  }

  function callApiToFetchCounterItemCountData(obj) {
    DashboardService.getCounterItemsCount(
      obj,
      (response) => {
        if (response != null) {
          const listData = [...itemListData];
          listData.map((item) => {
            if (item && item.id && item.id != "" && response && response[item.id] != null && response[item.id] >= 0) {
              item.count = response[item.id];
            } else {
              item.count = 0;
            }
          });
          setItemListData(listData);
        }
        setRefreshInterval(REFRESH_DASHBOARD_SCREEN_INTERVAL);
        setIsLoading(false);
      },
      (err) => {
        setRefreshInterval(0);
        console.error(err);
        setIsLoading(false);
        toastError("Error while Fetching Items Count");
      }
    );
  }

  function callApiToFetchCounterItemReport(obj) {
    DashboardService.getCounterItemsReport(
      obj,
      (response) => {
        if (response != null) {
          const { totalCount, counterItems } = response;
          if (Array.isArray(counterItems) && counterItems.length != null && counterItems.length > 0) {
            setCounterItemData(counterItems);
            // setItemIdIndex(fetchItemIdFromcounterItem(counterItems[0]));
            updateSelectedProductDetailsIndex(counterItems);
          }
          if (totalCount && !isNaN(parseInt(totalCount))) {
            setPendingItemCount(parseInt(totalCount));
          }
        } else {
          toastError("No Counter Item Data found");
          setSelectedProductDetailsIndex(0);
          localStorage.removeItem("selectedProductDetailsIndex");
        }
        setRefreshInterval(REFRESH_DASHBOARD_SCREEN_INTERVAL);
        setIsLoading(false);
      },
      (err) => {
        setRefreshInterval(0);
        console.error(err);
        setIsLoading(false);
        toastError("Error while Fetching Counter Item Data");
        setSelectedProductDetailsIndex(0);
        localStorage.removeItem("selectedProductDetailsIndex");
      }
    );
  }

  function updateSelectedProductDetailsIndex(counterItems) {
    let counterItemsData = null;
    if (localStorage.getItem("selectedProductDetailsIndex")) {
      const index = localStorage.getItem("selectedProductDetailsIndex");
      if (!isNaN(parseInt(index)) && parseInt(index) <= counterItems.length) {
        setSelectedProductDetailsIndex(parseInt(localStorage.getItem("selectedProductDetailsIndex")));
        counterItemsData = counterItems[parseInt(localStorage.getItem("selectedProductDetailsIndex"))];
      } else {
        setSelectedProductDetailsIndex(0);
        counterItemsData = counterItems[0];
        localStorage.setItem("selectedProductDetailsIndex", 0);
      }
    } else {
      setSelectedProductDetailsIndex(0);
      counterItemsData = counterItems[0];
      localStorage.setItem("selectedProductDetailsIndex", 0);
    }

    setSelectedProductDetailsObj(counterItemsData);
    setItemIdIndex(fetchItemIdFromcounterItem(counterItemsData));
    if (counterItemsData && counterItemsData.toleranceMinWeight == null && counterItemsData.toleranceMaxWeight == null) {
      updateScalableData(counterItemsData);
    }
  }

  function fetchItemIdFromcounterItem(counterItem) {
    if (counterItem.toleranceMaxWeight == null && counterItem.toleranceMinWeight == null) {
      // its a non-scalable counter item
      if (counterItem.items && Array.isArray(counterItem.items) && counterItem.items.length != null && counterItem.items.length > 0) {
        const actionedData = counterItem.items.find((item) => item.actioned == false);
        if (actionedData && actionedData.itemId != null && actionedData.itemId >= 0) {
          return actionedData.itemId;
        }
      }
    }
    return 0;
  }

  const searchOnChange = (event) => {
    event.preventDefault();
    if (event && event.target) {
      const { value } = event.target;
      const slotData = slotList.find((slot) => slot.slotName == value);

      if (slotData != null && typeof slotList == "object") {
        const { slotStartTimeInMillis = 0, slotEndTimeInMillis = 0 } = slotData;

        localStorage.setItem(
          "slotData",
          JSON.stringify({
            slotId: value,
            slotStartTime: slotStartTimeInMillis,
            slotEndTime: slotEndTimeInMillis,
          })
        );
        setTopMenuSearchObj((prevObj) => ({
          ...prevObj,
          slotId: value,
          slotStartTime: slotStartTimeInMillis,
          slotEndTime: slotEndTimeInMillis,
        }));
      }
      setRefreshInterval(0);
      resetView();
    }

    setTimeout(() => {
      document.activeElement.blur();
    }, 0);
  };

  const handleDateChangeEvent = (event, picker) => {
    if (event && event.target) {
      const { name } = event.target;
      if (name === "delDate") {
        const formattedDate = moment(picker.startDate).format("YYYY-MM-DD");
        setSelectedProductDetailsObj({});
        setDelLabel(formattedDate);
        setTopMenuSearchObj((prevObj) => ({
          ...prevObj,
          date: formattedDate,
          slotId: "",
          slotStartTime: "",
          slotEndTime: "",
        }));
      }
    }
  };

  const handleCancel = (event, picker) => {
    event.preventDefault();
    picker.element.val("");
    setTopMenuSearchObj((prevObj) => ({
      ...prevObj,
      date: "",
      slotId: "",
      slotStartTime: "",
      slotEndTime: "",
    }));
    setDelLabel("");
    setRefreshInterval(0);
  };

  const handleCountItemChange = (event, index, itemData) => {
    event.preventDefault();
    setSelectedProductDetailsObj({});
    localStorage.removeItem("selectedProductDetailsIndex");
    setSelectedCountItem(index);
    updateOrderTypeValue(itemData);
  };

  function updateOrderTypeValue(itemData) {
    const { orderType, prepared } = itemData;
    setTopMenuSearchObj((prevObj) => ({
      ...prevObj,
      orderType,
      prepared,
      pageNumber: 0,
    }));
    setRefreshInterval(0);
  }

  const handleChangePage = (event, newPage = 0) => {
    event.preventDefault();
    if (!isNaN(newPage) && newPage >= 0) {
      setTopMenuSearchObj((prevObj) => ({
        ...prevObj,
        pageNumber: newPage,
      }));
    }
    setRefreshInterval(0);
  };

  const updateSelectedProductDetailsObj = (event, itemIndex) => {
    event.preventDefault();
    if (!isNaN(itemIndex) && itemIndex >= 0) {
      const counterItem = counterItemData[itemIndex];
      setSelectedProductDetailsObj(counterItem);
      setItemIdIndex(fetchItemIdFromcounterItem(counterItem));
      setSelectedProductDetailsIndex(itemIndex);
      localStorage.setItem("selectedProductDetailsIndex", itemIndex);
      setInputBarCode("");
      if (counterItem && counterItem.toleranceMinWeight == null && counterItem.toleranceMaxWeight == null) {
        updateScalableData(counterItem);
      }
    }
  };

  function updateScalableData(counterItem) {
    const { items } = counterItem;
    let actionedData = 0;
    let outOfStockData = 0;
    if (items && Array.isArray(items) && items.length != null && items.length > 0) {
      items.map((item) => {
        if (item && item.actioned) {
          actionedData = actionedData + 1;
        }

        if (item && item.quantityPicked == 0) {
          outOfStockData = outOfStockData + 1;
        }
      });
    }
    setMarkedOutOfStock(outOfStockData);
    setTotalActionedItems(actionedData);
  }

  const reloadCounterItemsReport = (event) => {
    event.preventDefault();
    if (currentPosCode != null && currentPosCode != "" && delLabel != null && delLabel != "") {
      setIsTopLevelLoading(true);
      fetchSlotListBasedOnPosCode();
    } else {
      toastError("Store Code and Date required");
    }
  };

  const showHideNewWeightInputDialog = (event) => {
    event.preventDefault();
    setShowNewWeightInputDialog(false);
    setNewWeight("");
  };

  const onChangeInputBarCode = (event) => {
    event.preventDefault();
    setRefreshInterval(0);
    if (event && event.target) {
      const { value } = event.target;
      setInputBarCode(value);
    }
  };

  const onChangeInputNewWeight = (event) => {
    event.preventDefault();
    if (event && event.target) {
      const { value } = event.target;
      setNewWeight(value);
    }
  };

  const actionOnItemTo = (event, type) => {
    event.preventDefault();
    setRefreshInterval(0);
    if (type != null && type != "" && typeof selectedProductDetailsObj === "object" && Object.keys(selectedProductDetailsObj).length !== 0) {
      switch (type) {
        case NOT_AVAILABLE: {
          prepareNotAvailable();
          break;
        }
        case SCAN_PRODUCT: {
          setPrepareData({});
          setInputBarCode("");
          break;
        }
        default:
          console.error("No such criteria found");
      }
    }
  };

  function prepareNotAvailable() {
    const { consignmentCode, sku, items, toleranceMaxWeight, toleranceMinWeight, source } = selectedProductDetailsObj;
    let prepareData = {
      consignmentCode,
      sku,
      pickedWeightedQty: null,
      pickedQuantity: 0,
      itemId: null,
    };

    if (items && Array.isArray(items) && items.length != null && items.length > 0) {
      let isNonScalable = toleranceMaxWeight == null && toleranceMinWeight == null ? true : false;
      if (isNonScalable) {
        prepareData.itemId = itemIdIndex;
      }
    }

    createObjAndCallPrepareAPI(prepareData, source);
  }

  function prepareScanedItem() {
    setItemSource("");
    if (inputBarCode && inputBarCode != "") {
      const { consignmentCode, sku, items, toleranceMaxWeight, toleranceMinWeight, source } = selectedProductDetailsObj;
      let prepareData = {
        consignmentCode,
        sku,
        pickedWeightedQty: null,
        pickedQuantity: 1,
        itemId: null,
      };

      let isNonScalable = toleranceMaxWeight == null && toleranceMinWeight == null ? true : false;
      if (isNonScalable) {
        if (Array.isArray(items) && items.length != null && items.length > 0) {
          let isBarCodeValid = false;
          if (selectedProductDetailsObj.uom != null && selectedProductDetailsObj.uom != "" && (selectedProductDetailsObj.uom == PIECES || selectedProductDetailsObj.uom == PIECE)) {
            const orderedBarCodeArr = selectedProductDetailsObj.orderedBarcode.split("");
            const orderedBarCode = orderedBarCodeArr.slice(0, 8).join("");

            const barCodeArr = inputBarCode.split("");
            const barCodeDigits = barCodeArr.slice(0, 8).join("");
            isBarCodeValid = checkIfBarCodeCorrect(barCodeDigits, orderedBarCode);
          } else {
            isBarCodeValid = checkIfBarCodeCorrect(inputBarCode, selectedProductDetailsObj.orderedBarcode);
          }
          if (isBarCodeValid) {
            prepareData.itemId = itemIdIndex;
            createObjAndCallPrepareAPI(prepareData, source);
          } else {
            toastWarn(`Entered Bar code ${inputBarCode} is not valid`);
            clearInputBarCode();
          }
        } else {
          toastError("Error !!! No Items found");
        }
      } else {
        checkBarCodeValidAndFetchWeightedQty(prepareData, source);
      }
    } else {
      toastWarn("Please enter a valid Bar code");
      clearInputBarCode();
    }
  }

  function checkIfBarCodeCorrect(enteredBarCode, orderedBarcode) {
    return enteredBarCode == orderedBarcode;
  }

  function checkBarCodeValidAndFetchWeightedQty(prepareData, source) {
    /*  Check if the bar code valid
          success ::
              Check if the scanned weight range is between the range
                Success ::
                  update pickedWeightedQty and call prepare API
                Failure ::
                  give user option to enter a new weight
          failure ::
              enter valid barcode
    */

    const orderedBarCodeArr = selectedProductDetailsObj.orderedBarcode.split("");
    const orderedBarCode = orderedBarCodeArr.slice(0, 8).join("");

    const barCodeArr = inputBarCode.split("");
    if (Array.isArray(barCodeArr) && barCodeArr.length != null && barCodeArr.length > 0 && barCodeArr.length == 13) {
      const barCodeDigits = barCodeArr.slice(0, 8).join("");
      const weightDigit = barCodeArr.slice(8, 12).join("");

      const isBarCodeValid = checkIfBarCodeCorrect(orderedBarCode, barCodeDigits);
      if (isBarCodeValid) {
        const enteredWeightValid = checkBarCodeWeight(parseInt(weightDigit));
        if (enteredWeightValid) {
          setInputBarCode("");
          prepareData.pickedWeightedQty = parseInt(weightDigit);
          createObjAndCallPrepareAPI(prepareData, source);
        } else {
          document.getElementById("inputBarCode").blur();
          setPrepareData(prepareData);
          setShowNewWeightInputDialog(true);
          setNewWeight(parseInt(weightDigit));
          setItemSource(source);
        }
      } else {
        toastWarn(`Entered Bar code ${inputBarCode} is not valid`);
        clearInputBarCode();
      }
    } else {
      toastError(BAR_CODE_LENGTH_ERROR);
      clearInputBarCode();
    }
  }

  function checkBarCodeWeight(weightDigit) {
    const { toleranceMaxWeight, toleranceMinWeight } = selectedProductDetailsObj;
    if (parseInt(weightDigit) > 0 && parseInt(weightDigit) <= toleranceMaxWeight && parseInt(weightDigit) >= toleranceMinWeight) {
      return true;
    } else {
      setExternalDialogText(`Weight should be (${toleranceMinWeight} - ${toleranceMaxWeight} gms) only.`);
      return false;
    }
  }

  function createObjAndCallPrepareAPI(prepareData, source = "HYPERMARKET") {
    const objData = {
      storeCode: currentPosCode,
      source,
      prepareData,
    };
    callPrepareAPI(objData);
  }

  function callPrepareAPI(objData) {
    DashboardService.getCounterItemsPrepareApiCall(
      objData,
      () => {
        setInputBarCode("");
        fetchCounterItemsData();
        setItemSource("");
      },
      (err) => {
        console.error(err);
        toastError("Error occured while executing Prepare Counter Items API");
        setItemSource("");
      }
    );
  }

  const validateWeight = (event) => {
    event.preventDefault();
    if (newWeight != null && !isNaN(newWeight) && parseInt(newWeight) > 0) {
      const newWeightValid = checkBarCodeWeight(newWeight);
      if (newWeightValid) {
        const newPrepareData = Object.assign({}, prepareDataObj);
        newPrepareData.pickedWeightedQty = parseInt(newWeight);
        setPrepareData({});
        setShowNewWeightInputDialog(false);
        setNewWeight("");
        setExternalDialogText(null);
        createObjAndCallPrepareAPI(newPrepareData, itemSource);
      } else {
        toastError(externalDialogText);
      }
    } else {
      toastError("Please enter a valid weight");
    }
  };

  const onKeyPressBarcode = () => {
    if (event && event.keyCode && event.keyCode == 13) {
      prepareScanedItem();
    }
  };

  const clearBarCode = () => {
    clearInputBarCode();
  };

  function clearInputBarCode() {
    setInputBarCode("");
  }

  useEffect(() => {
    return () => {
      setRefreshInterval(0);
      setCountDownInterval(0);
      clearInterval();
    };
  }, []);

  useEffect(() => {
    if (refreshInterval && refreshInterval > 0) {
      setCountDownInterval(refreshInterval);
      const timeInterval = setInterval(refereshScreen, refreshInterval);
      return () => clearInterval(timeInterval);
    } else {
      setCountDownInterval(0);
    }
  }, [refreshInterval]);

  useEffect(() => {
    if (countDownInterval && countDownInterval > 0) {
      const screenTimerInterval = setInterval(refereshTimer, 1000);
      return () => clearInterval(screenTimerInterval);
    }
  }, [countDownInterval]);

  function refereshScreen() {
    if (currentPosCode != null && currentPosCode != "" && delLabel != null && delLabel != "") {
      setIsTopLevelLoading(true);
      setCountDownInterval(REFRESH_DASHBOARD_SCREEN_INTERVAL);
      fetchSlotListBasedOnPosCode();
    } else {
      toastError("Store Code and Date required");
    }
  }

  function refereshTimer() {
    let milliSecond = countDownInterval - 1000;
    const timeValue = millisToMinutesAndSeconds(milliSecond);
    setCountDownInterval(milliSecond);
    setMinuteLeft(timeValue.minutes);
    setSecondLeft(timeValue.seconds);
  }

  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    const obj = {
      minutes: minutes,
      seconds: seconds,
    };
    return obj;
  }

  return (
    <>
      <TopMenuPage />
      <div className={dashboardClasses.dashboardPageStyle}>
        {isTopLevelLoading ? (
          <div className={dashboardClasses.loadingMainDiv}>
            <div className={dashboardClasses.loadingMainDivInner}>
              <CircularProgress disableShrink thickness={3} size={50} />
            </div>
          </div>
        ) : (
          <>
            <DashboardTopSearchBarPage
              topMenuSearchObj={topMenuSearchObj}
              delLabel={delLabel}
              searchOnChange={searchOnChange}
              handleDateChangeEvent={handleDateChangeEvent}
              handleCancel={handleCancel}
              slotList={slotList}
            />
            {isLoading ? (
              <div className={dashboardClasses.loadingDiv}>
                <div className={dashboardClasses.loadingMainDivInner}>
                  <CircularProgress disableShrink thickness={2} size={30} />
                </div>
              </div>
            ) : (
              <div className={dashboardClasses.dashboardMainContentStyle}>
                <div className={dashboardClasses.mainContentLeft}>
                  <DashboardItemsCountPage itemListData={itemListData} selectedCountItem={selectedCountItem} handleCountItemChange={handleCountItemChange} />
                  <DashboardItemsListPage
                    counterItemData={counterItemData}
                    pendingItemCount={pendingItemCount}
                    topMenuSearchObj={topMenuSearchObj}
                    handleChangePage={handleChangePage}
                    selectedCountItem={selectedCountItem}
                    selectedProductDetailsIndex={selectedProductDetailsIndex}
                    updateSelectedProductDetailsObj={updateSelectedProductDetailsObj}
                    reloadCounterItemsReport={reloadCounterItemsReport}
                    minuteLeft={minuteLeft}
                    secondLeft={secondLeft}
                    countDownInterval={countDownInterval}
                  />
                </div>

                <div className={dashboardClasses.mainContentRight}>
                  <DashboardItemDetailsPage
                    selectedProductDetailsObj={selectedProductDetailsObj}
                    actionOnItemTo={actionOnItemTo}
                    itemIdIndex={itemIdIndex}
                    selectedCountItem={selectedCountItem}
                    inputBarCode={inputBarCode}
                    onChangeInputBarCode={onChangeInputBarCode}
                    onKeyPressBarcode={onKeyPressBarcode}
                    clearBarCode={clearBarCode}
                    showNewWeightInputDialog={showNewWeightInputDialog}
                    totalActionedItems={totalActionedItems}
                    markedOutOfStock={markedOutOfStock}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <React.Fragment>
        {showNewWeightInputDialog ? (
          <DialogSingleInputBox
            open={showNewWeightInputDialog}
            onClose={showHideNewWeightInputDialog}
            headerText={WEIGHT_TEXT}
            placeholderText={WEIGHT_TEXT}
            value={newWeight}
            onChange={onChangeInputNewWeight}
            externalText={externalDialogText}
            proceedAction={validateWeight}
          />
        ) : (
          <></>
        )}
      </React.Fragment>
    </>
  );
};

export default React.memo(DashboardPage);
