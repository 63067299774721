import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  itemDescriptionDiv: {
    width: "100%",
    height: "54px",
    margin: 0,
    padding: 0,
  },
  itemDescriptionDetailDiv: {
    width: "50%",
    float: "left",
    display: "grid",
  },
  fontStyleHeader: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#031629",
    marginBottom: "8px",
  },
  fontStyleValue: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#031629",
    marginBottom: "12px",
  },
});

const ItemDescriptionDetailDiv = ({ name1, value1, name2, value2 }) => {
  const classes = useStyles();

  return (
    <div className={classes.itemDescriptionDiv}>
      <div className={classes.itemDescriptionDetailDiv}>
        <span className={classes.fontStyleHeader}>{name1 || "-"}</span>
        <span className={classes.fontStyleValue}>{value1 || "-"}</span>
      </div>
      <div className={classes.itemDescriptionDetailDiv}>
        <span className={classes.fontStyleHeader}>{name2 || "-"}</span>
        <span className={classes.fontStyleValue}>{value2 || "-"}</span>
      </div>
    </div>
  );
};

ItemDescriptionDetailDiv.propTypes = {
  name1: PropTypes.any.isRequired,
  value1: PropTypes.any.isRequired,
  name2: PropTypes.any.isRequired,
  value2: PropTypes.any.isRequired,
};

export default ItemDescriptionDetailDiv;
