import { makeStyles } from "@material-ui/core";

export const searchBarStyle = makeStyles(() => ({
  topSearchBarStyle: {
    width: "100%",
    padding: "20px 0",
    display: "flex",
    justifyContent: "space-between",
  },
  topSearchBarLeft: {
    width: "65%",
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "24px",
  },

  topSearchBarLeftLeft: {
    display: "flex",
    justifyContent: "flex-start",
  },
  dashboardTextstyle: {
    marginTop: "auto",
    marginBottom: "auto",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "29px",
    color: "#06284B",
  },

  topSearchBarLeftRight: {
    marginTop: "auto",
    marginBottom: "auto",
    display: "flex",
    justifyContent: "flex-end",
  },
  searchInputDiv: {
    height: "40px",
    width: "200px",
    display: "grid",
    float: "left",
    padding: 0,
    paddingLeft: "12px",
    marginBottom: 0,
  },
  labelOnInputBoxFontStyle: {
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "1px",
    color: "#252525 !important",
    paddingLeft: "2px",
    "& .MuiFormLabel-root": {
      fontFamily: "Montserrat",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "1px",
      color: "#252525",
      paddingLeft: "2px",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#252525 !important",
    },
    "& .Mui-focused": {
      color: "#252525",
      fontWeight: "500",
    },
  },
  selectBoxInputDivStyle: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "8px",
    textAlign: "left",
    height: "40px",
    background: "#FFFFFF",
    border: "1px solid #B2B2B2",
    borderRadius: "8px",
    outline: "0 !important",
    backgroundColor: "#FFFFFF !important",
    "&:hover": { backgroundColor: "#FFFFFF" },
    "& .MuiSelect-select:focus": { background: "rgba(255,255,255,0.1)" },
    "&.MuiFilledInput-root.Mui-focused": {
      border: "1px solid #0A437C",
      borderRadius: "8px 8px 0 0",
      outline: "none",
    },
  },
  selectBoxOpenInputDivStyle: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "8px",
    textAlign: "left",
    height: "40px",
    background: "#FFFFFF",
    border: "1px solid #0A437C",
    borderRadius: "8px 8px 0 0",
    outline: "0 !important",
    backgroundColor: "#FFFFFF !important",
    "&:hover": { backgroundColor: "#FFFFFF" },
    "& .MuiSelect-select:focus": { background: "rgba(255,255,255,0.1)" },
    "&.MuiFilledInput-root.Mui-focused": {
      border: "1px solid #0A437C",
      borderRadius: "8px 8px 0 0",
      outline: "none",
    },
  },
  dropDownIconStyle: {
    position: "absolute",
    marginLeft: "162px",
    pointerEvents: "none",
  },
  selectBoxDropdownPaper: {
    border: "1px solid #0A437C",
    borderTop: "none",
    fontFamily: "Montserrat",
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: "500",
    overflow: "auto",
    maxHeight: "300px",
    marginTop: "-5px",
    borderRadius: "0 0 8px 8px",
    marginLeft: "-1px",
    width: "188px",
    minWidth: "188px",
    maxWidth: "188px",
  },
  selectBoxMenuItemTextStyle: {
    paddingRight: 0,
    paddingLeft: "12px",
    width: "100%",
    height: "40px",
    fontFamily: "Montserrat",
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: "500",
    color: "#252525",
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#0E5AA7",
    },
  },
  dateSearchBoxOneDate: {
    width: "147px",
    height: "40px",
    paddingLeft: "10px",
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "15px",
    background: "#FFFFFF",
    border: "1px solid #B2B2B2",
    borderLeft: "none",
    borderRadius: "0px 8px 8px 0px",
    outline: "0 !important",
    "&::placeholder": {
      padding: "0",
      paddingLeft: "12px",
      color: "#252525",
      textAlign: "left",
      fontWeight: "400",
      width: "100%",
    },
  },

  topSearchBarRight: {
    width: "35%",
    display: "flex",
    justifyContent: "center",
  },
  startPreparationButton: {
    height: "45px",
    margin: "auto 20px",
    width: "100%",
    background: "#E6E6E6", //#0E5AA7",
    border: "1px solid #E6E6E6", //"1px solid #0E5AA7",
    borderRadius: "8px",
    outline: "0 !important",
  },
  startPreparationButtonText: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#B2B2B2", // #FFFFFF",
  },
  searchInputDateDiv: {
    display: "flex",
    width: "200px",
    maxWidth: "200px",
    marginTop: "auto",
    marginBottom: "auto",
  },
  dateSearchBoxSelect: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "0px",
    textAlign: "left",
    height: "40px",
    width: "40px",
    maxWidth: "40px",
    background: "#F5F5F5",
    border: "1px solid #B2B2B2",
    borderRight: "none",
    borderRadius: "8px 0 0 8px",
    outline: "0 !important",
    backgroundColor: "#F5F5F5 !important",
    "&:hover": { backgroundColor: "#F5F5F5" },
    "& .MuiSelect-select:focus": { background: "rgba(255,255,255,0.1)" },
  },
  dateSearchBoxSelectIcon: {
    display: "none",
  },
}));
