import { makeStyles } from "@material-ui/core/styles";

const TopBarHeight = 80;

export const topMenuStyles = makeStyles((theme) => ({
  appBar: {
    width: "100%",
    height: `${TopBarHeight}px`,
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.05)",

    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  topBar: {
    width: "100%",
    height: `${TopBarHeight}px`,
    padding: "15px",
    margin: 0,
    display: "flex",
  },
  topBarLeft: {
    width: "20%",
    display: "flex",
    justifyContent: "flex-start",
  },
  mainTitle: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "40px",
    lineHeight: "49px",
    color: "#0E5AA7",
    marginLeft: "15px",
    marginTop: "auto",
    marginBottom: "auto",
  },
  topBarRight: {
    width: "80%",
    display: "flex",
    justifyContent: "flex-end",
    cursor: "default",
  },
  topMenuItem: {
    margin: 0,
    padding: 0,
    background: "#FFFFFF",
    cursor: "default",
    "&:hover": {
      background: "#FFFFFF",
    },
    "&:focus": {
      background: "#FFFFFF",
    },
  },
  topMenuItemFocus: {
    padding: 0,
    paddingRight: "0px !important",
    marginLeft: "auto",
    background: "#FFFFFF !important",
    backgroundColor: "#FFFFFF !important",
    "& .MuiSelect-select:focus": {
      background: "rgba(255,255,255,0.1)",
      backgroundColor: "rgba(255,255,255,0.1)",
    },
  },
  topMenuFormDivStyle: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "auto",
    marginBottom: "auto",
  },
  topMenuDividerStyle: {
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: "15px",
    marginRight: "15px",
  },
  inputLabelStyle: {
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: "5px",
    fontFamily: "Montserrat",
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: "500",
    color: "#252525",
  },
  selectMenuInputStyle: {
    marginTop: "auto",
    marginBottom: "auto",
    fontFamily: "Montserrat",
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: "600",
    color: "#252525",
    height: "100%",
    background: "#FFFFFF",
    cursor: "default",
    "&:hover": {
      border: "none",
      borderRadius: "0px",
      background: "#FFFFFF",
      fontFamily: "Montserrat",
      fontSize: "12px",
      lineHeight: "15px",
      fontWeight: "600",
    },
    "&:focus": {
      border: "none",
      borderRadius: "0px",
      background: "#FFFFFF",
      fontFamily: "Montserrat",
      fontSize: "12px",
      lineHeight: "15px",
      fontWeight: "600",
    },
  },
  DropDownIcon: {
    marginLeft: "12px",
    pointerEvents: "none",
  },
  selectDropdown: {
    border: "1px solid #0A437C",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: "500",
  },
  listFontStyle: {
    marginTop: "auto",
    marginBottom: "auto",
    fontFamily: "Montserrat",
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: "600",
    color: "#252525",
  },
  listFontStyleNoBold: {
    marginTop: "auto",
    marginBottom: "auto",
    fontFamily: "Montserrat",
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: "400",
    color: "#252525",
  },
  userDetail: {
    marginTop: "auto",
    marginBottom: "auto",
    fontFamily: "Montserrat",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "15px",
    textAlign: "right",
    color: "#252525",
    marginRight: "12px",
    cursor: "pointer",
  },
  userRole: {
    fontFamily: "Montserrat",
    float: "right",
    fontWeight: "bold",
  },
  accountCircleDiv: {
    marginTop: "auto",
    marginBottom: "auto",
    width: "40px",
    maxWidth: "40px",
    minWidth: "40px",
    marginRight: "5px",
    // background : 'rgba(14, 90, 167, 0.1)',
    // borderRadius : '50%'
  },
  userIconStyle: {
    cursor: "pointer",
  },
}));
