import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { fetchAllCountries, fetchPosList, fetchCategoryList } from "../../api/loginAPI";

import { API_RESPONSE_STATUS } from "../AppConstant";

export const fetchListOfCountries = createAsyncThunk("configurations/fetchListOfCountries", async (_, { rejectWithValue }) => {
  try {
    const response = await fetchAllCountries();
    if (response && response.data && response.data.countries && Array.isArray(response.data.countries) && response.data.countries.length != null && response.data.countries.length > 0) {
      return response.data.countries;
    }
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchListOfPosByCountry = createAsyncThunk("configurations/fetchListOfPosByCountry", async (countryId, { rejectWithValue }) => {
  try {
    const response = await fetchPosList(countryId);
    if (response && response.data && Array.isArray(response.data)) {
      return response.data;
    }
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const fetchListOfCategory = createAsyncThunk("configurations/fetchListOfCategory", async (_, { rejectWithValue }) => {
  try {
    const response = await fetchCategoryList();
    if (response && response.categories != null && Array.isArray(response.categories)) {
      const listData = [];
      response.categories.forEach((item) => {
        const code = Object.keys(item)[0];
        const name = item[code];
        const objData = { code, name };
        listData.push(objData);
      });
      return listData;
    }
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const commonFetchInitialState = {
  fetchStatus: API_RESPONSE_STATUS.IDLE,
  data: [],
  error: "",
};

const initialState = {
  countries: { ...commonFetchInitialState },
  posList: { ...commonFetchInitialState },
  categoriesList: { ...commonFetchInitialState },
};

const configurationSlice = createSlice({
  name: "configurations",
  initialState,
  reducers: {
    resetAppConfigData: () => initialState,
  },
  extraReducers: {
    [fetchListOfCountries.pending]: (state) => {
      state.countries.fetchStatus = API_RESPONSE_STATUS.LOADING;
      state.countries.error = "";
      state.countries.data = [];
    },
    [fetchListOfCountries.fulfilled]: (state, { payload = [] }) => {
      state.countries.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
      state.countries.data = [...payload];
      state.countries.error = "";
    },
    [fetchListOfCountries.rejected]: (state) => {
      state.countries.fetchStatus = API_RESPONSE_STATUS.FAILED;
      state.countries.data = [];
      state.countries.error = "";
    },
    [fetchListOfPosByCountry.pending]: (state) => {
      state.posList.fetchStatus = API_RESPONSE_STATUS.LOADING;
      state.posList.error = "";
      state.posList.data = [];
    },
    [fetchListOfPosByCountry.fulfilled]: (state, { payload }) => {
      state.posList.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
      state.posList.data = [...payload];
      state.posList.error = "";
    },
    [fetchListOfPosByCountry.rejected]: (state) => {
      state.posList.fetchStatus = API_RESPONSE_STATUS.FAILED;
      state.posList.data = [];
      state.posList.error = "";
    },
    [fetchListOfCategory.pending]: (state) => {
      state.categoriesList.fetchStatus = API_RESPONSE_STATUS.LOADING;
      state.categoriesList.error = "";
    },
    [fetchListOfCategory.fulfilled]: (state, { payload }) => {
      state.categoriesList.fetchStatus = API_RESPONSE_STATUS.SUCCEEDED;
      state.categoriesList.data = [...payload];
      state.categoriesList.error = "";
    },
    [fetchListOfCategory.rejected]: (state) => {
      state.categoriesList.fetchStatus = API_RESPONSE_STATUS.FAILED;
      state.categoriesList.data = [];
      state.categoriesList.error = "";
    },
  },
});

export const { resetAppConfigData } = configurationSlice.actions;

export default configurationSlice.reducer;
